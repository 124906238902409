import { v4 as uuid } from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import { CustomReactMarkdown } from '@uplight/marketplace-components';
import FormContainer from '../../../../shared/components/FormContainer/FormContainer';


const TermsAndConditions = ({ termsItemized }) => {
  if (!termsItemized || termsItemized.length === 0) {
    return '';
  }

  return (
    <>
      <h2 className="terms-and-conditions__section-title">Agree to the Terms &amp; Conditions</h2>
      {termsItemized.map(term => (
        <FormContainer title={term.title} key={uuid()}>
          <ul className="terms-and-conditions">
            {term.items.map(item => (
              <li className="terms-and-conditions__item" key={uuid()}>
                <CustomReactMarkdown source={item} />
              </li>
            ))}
          </ul>
        </FormContainer>
      ))}
    </>
  );
};

TermsAndConditions.propTypes = {
  termsItemized: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
};

export default TermsAndConditions;
