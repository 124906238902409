import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import NavBarInfo from './components/NavBarInfo';
import ArrowDown from '../../shared/icons/icon-arrow-down.svg';


const NavBar = ({ items, header }) => {
  const navClass = classNames('nav-bar', {
    'nav-bar--empty': items.length === 0,
  });

  return (
    <div className={navClass}>
      {items.map((item) => {
        const itemClassNames = classNames(
          'nav-bar__link-wrapper',
          `nav-bar__${item.id}`,
          { 'nav-bar__link-wrapper--active': item.active },
        );

        return (
          <div key={item.id} className={itemClassNames}>
            <a
              className="nav-bar__link"
              href={item.url}
            >
              {item.id === 'selected-program' && (<ArrowDown className="nav-bar__program-icon" />)}
              {item.name}
            </a>
          </div>
        );
      })}
      {header && (
        <>
          <div className="spacer" />
          <NavBarInfo id="header-nav" {...header} />
        </>
      )}
    </div>
  );
};

NavBar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
  })),
  header: PropTypes.shape({}),
};

NavBar.defaultProps = {
  items: [],
  header: null,
};

export default NavBar;
