const getValueFromWindow = function getValueFromWindowHelper(prop, key) {
  const { upFreeProducts } = window;

  if (
    !upFreeProducts ||
    !Object.hasOwnProperty.call(upFreeProducts, prop) ||
    !Object.hasOwnProperty.call(upFreeProducts[prop], key)
  ) {
    return '';
  }

  return window.upFreeProducts[prop][key];
};


export default getValueFromWindow;
