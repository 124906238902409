import getValueFromWindow from '../../../../shared/helpers/getValueFromWindow';


const getOrderSubmissionErrorMessage = function getOrderSubmissionErrorMessage(errorMsg) {
  const { supportInfo } = getValueFromWindow('globals', 'tenant');
  const errorMessages = {
    orderLimit: 'Unfortunately, we are unable to process your order as the offer has ended',
    default: `Your order could not be processed. Please refresh the page and re-enter your information or contact support at ${supportInfo.phoneNumber} or ${supportInfo.email} for help placing your order.`,
  };

  return errorMessages[errorMsg];
};

export default getOrderSubmissionErrorMessage;
