import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from '@uplight/marketplace-forms';
import { SubmissionError } from '@uplight/marketplace-components';
import shippingFormRenderer from './ShippingFormRenderer';
import { controlsRendererMap } from './formConfig';
import ShippingInfo from './ShippingInfo';
import getValueFromWindow from '../../../shared/helpers/getValueFromWindow';
import getOrderSubmissionErrorMessage from './helpers/getOrderSubmissionErrorMessage';
import { CompleteOrderClicked, OrderSubmitFailure } from '../../analytics/events';
import Analytics from '../../../analytics/GoogleAnalytics';


class Shipping extends Component {
  constructor(props) {
    super(props);
    this.tenant = getValueFromWindow('globals', 'tenant');

    this.submit = this.submit.bind(this);
    this.googleAnalytics = new Analytics();
  }

  componentDidUpdate() {
    const { submitOrderFailure } = this.props;

    if (submitOrderFailure) {
      this.googleAnalytics.trackEvent(OrderSubmitFailure);
    }
  }

  submit() {
    const { onSubmitHandler } = this.props;

    this.googleAnalytics.trackEvent(CompleteOrderClicked);
    onSubmitHandler();
  }

  /* eslint-disable-next-line complexity */
  render() {
    const {
      inFlight,
      rebateAddress,
      formData,
      reportFormValues,
      raasEligibilityStatus,
      resetEligibility,
      showInvalidFormMessage,
      validateControls,
      onQueueComplete,
      submitOrderFailure,
      isOrderLimitReached,
      customValidator,
    } = this.props;

    const passThroughProps = {
      raasEligibilityStatus,
      resetEligibility,
      rebateAddress,
      utilityName: this.tenant.displayName,
      customValidator,
    };

    const hasRebateAddress = Object.keys(rebateAddress).length > 0;
    const headingText = hasRebateAddress ? 'Confirm your shipping information' : 'Enter your shipping address';
    const errorMsg = isOrderLimitReached ? 'orderLimit' : 'default';

    return (
      <div className="order-process-step__container">
        <p className="order-process-step__header">{headingText}</p>
        {hasRebateAddress &&
          <ShippingInfo rebateAddress={rebateAddress} utilityName={this.tenant.displayName} />
        }
        <p className="order-process-step__description">
          Your order will typically ship within 7 business days after it has been processed.
        </p>
        <Form
          formData={formData}
          reportFormValues={reportFormValues}
          controlsRendererMap={controlsRendererMap}
          customRenderer={shippingFormRenderer}
          onQueueComplete={onQueueComplete}
          passThroughProps={passThroughProps}
          validateControls={validateControls}
        />
        <div className="shipping__container__horizontal-divider" />
        {showInvalidFormMessage && (
          <div className="text-input-control__error--single-use">Please complete all of the required fields above</div>
        )}
        <button type="button" onClick={this.submit} className="shipping-form__submit">
          Complete order
          {inFlight && <span className="button-loader" /> }
        </button>
        {!inFlight && submitOrderFailure && (
          <div className="identity-errors">
            <SubmissionError message={(getOrderSubmissionErrorMessage(errorMsg))} />
          </div>
        )}
      </div>
    );
  }
}

Shipping.propTypes = {
  inFlight: PropTypes.bool.isRequired,
  rebateAddress: PropTypes.shape({}).isRequired,
  formData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  reportFormValues: PropTypes.func.isRequired,
  raasEligibilityStatus: PropTypes.string.isRequired,
  resetEligibility: PropTypes.bool.isRequired,
  showInvalidFormMessage: PropTypes.bool.isRequired,
  validateControls: PropTypes.string.isRequired,
  onQueueComplete: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  submitOrderFailure: PropTypes.bool.isRequired,
  isOrderLimitReached: PropTypes.bool.isRequired,
  customValidator: PropTypes.func,
};


Shipping.defaultProps = {
  customValidator: null,
};

export default Shipping;
