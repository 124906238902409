import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Header from '../../../Header/Header';
import Footer from '../../../Footer/Footer';


const Overlay = function Overlay({ children }) {
  const {
    global_header: headerProps,
    global_footer: footerProps,
  } = window.upFreeProducts;

  return ReactDOM.createPortal(
    <div className="overlay-container">
      <Header {...headerProps} />
      {children}
      <Footer {...footerProps} />
    </div>,
    document.getElementsByTagName('body')[0],
  );
};

Overlay.propTypes = {
  children: PropTypes.element,
};

Overlay.defaultProps = {
  children: null,
};

export default Overlay;
