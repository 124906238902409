import React from 'react';

import getValueFromWindow from '../../../shared/helpers/getValueFromWindow';
import IconUnavailableProduct from '../../../shared/icons/icon-unavailable-product.svg';


const UnavailableProduct = function CheckoutConfirmationComponent() {
  const tenant = getValueFromWindow('globals', 'tenant');

  return (
    <div className="order-process-step__container">
      <p className="order-process-step__header">Sorry! Unfortunately this item is no longer available.</p>
      <div className="unavailable-product__message">
        {`You can still shop for energy-efficient products and enroll in home services on the ${tenant.displayName} marketplace.`}
      </div>
      <IconUnavailableProduct className="unavailable-product__icon" />
      <a href={tenant.siteUrl} className="unavailable-product__browse-button">
        {`Browse the ${tenant.displayName} Marketplace`}
      </a>
    </div>
  );
};

export default UnavailableProduct;
