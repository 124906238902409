import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import BackIcon from '../../../shared/icons/icon-back.svg';
import InfoIcon from '../../../shared/icons/icon-info.svg';
import isEnter from '../../../shared/helpers/isEnter';


const NavPane = ({
  activePane,
  backItemId,
  displayName,
  paneId,
  items,
  backHandler,
}) => activePane === paneId && (
  <>
    <li
      key={uuid()}
      className="nav-drawer__link-list__item--header"
    >
      <div
        className="nav-drawer__link-back"
        onClick={() => backHandler(backItemId)}
        onKeyUp={event => isEnter(event) && backHandler(backItemId)}
        role="button"
        tabIndex={0}
      >
        <BackIcon className="nav-drawer__back" />
        <div className="nav-drawer__link-back-text">
          <InfoIcon className="nav-drawer__link-icon" />
          <span>{displayName}</span>
        </div>
      </div>
    </li>
      {items.map(item => (
        <li
          key={uuid()}
          className="nav-drawer__link-list__item"
        >
          <a
            href={item.url}
            className="nav-drawer__link nav-drawer__link--active"
            tabIndex={0}
          >
            {item.name}
          </a>
        </li>
      ))}
  </>
);

NavPane.propTypes = {
  activePane: PropTypes.string,
  backItemId: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  backHandler: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  paneId: PropTypes.string.isRequired,
};

NavPane.defaultProps = {
  activePane: null,
  items: [],
};

export default NavPane;
