import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import isEnter from '../../helpers/isEnter';


const withDisclosureControl = (WrappedComponent, TriggerComponent) => {
  const DisclosureControl = (props) => {
    const { id, name } = props;
    const [isOpen, setIsOpen] = useState(false);

    const componentRef = useRef(null);

    useEffect(() => {
      const handleGlobalEvent = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('click', handleGlobalEvent);
      document.addEventListener('keydown', handleGlobalEvent);
      document.addEventListener('focus', handleGlobalEvent);

      return () => {
        document.removeEventListener('click', handleGlobalEvent);
        document.removeEventListener('keydown', handleGlobalEvent);
        document.removeEventListener('focus', handleGlobalEvent);
      };
    }, []);

    const handleClick = () => {
      setIsOpen(!isOpen);
    };

    const handleKeyPress = useCallback((event) => {
      if (isOpen && event.key === 'Escape') {
        setIsOpen(false);
        event.preventDefault();
      }

      if (isEnter(event)) {
        setIsOpen(!isOpen);
        event.preventDefault();
      }
    }, [isOpen]);


    return (
      <div className="disclosure-control" ref={componentRef}>
        <button
          aria-expanded={isOpen}
          aria-controls={id}
          aria-label={`Toggle ${name} menu`}
          onClick={handleClick}
          onKeyDown={handleKeyPress}
          tabIndex="0"
          className="disclosure-control__trigger-button"
          type="button"
        >
          <TriggerComponent name={name} isOpen={isOpen} />
        </button>
        {isOpen && <WrappedComponent {...props} isOpen={isOpen} setIsOpen={setIsOpen} />}
      </div>
    );
  };

  DisclosureControl.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  };

  return DisclosureControl;
};

export default withDisclosureControl;
