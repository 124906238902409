/* eslint-disable camelcase */
const validateEmailLocal = (email) => {
  // const dns = require('dns');
  const emailConfig = {
    emailRegex: '^\\w+([.%+-]\\w+)*@\\w+([.-]\\w+)*\\.[\\w]{2,6}$',
    skipValidationForDomains: ['evergy.co', 'evergy.com'],
  };

  const emailRegex = new RegExp(emailConfig.emailRegex);
  const { skipValidationForDomains } = emailConfig;

  const response = { valid_format: false, valid_dns_records: false, dns_records: [] };

  // Validate email format
  const regexMatch = new RegExp(emailRegex).test(email);

  response.valid_format = regexMatch;
  if (!response.valid_format) {
    return response;
  }

  const domain = email.split('@')[1];

  // Skip DNS validation for specific domains
  if (skipValidationForDomains.indexOf(domain) > -1) {
    response.valid_dns_records = true;
    return response;
  }

  // eslint-disable-next-line max-len
  // TODO: We need to implement the DNS validation for the email domain to check if the domain has valid DNS records.
  /*
    // Check for MX records
    dns.resolveMx(domain, function(error, mxRecords) {
        if (error){
            if (error.code === 'ENODATA' || error.code === 'NODATA') {
                response.valid_dns_records = false;
            }
            return response;
        }
        response.valid_dns_records = true;
        response.dns_records = collections.map(mxRecords, function (record) {
            return record.exchange;
        });
        return response;

        // Check for A records if no MX records
        dns.resolve(domain, function (error, aRecords) {
            if (error) {
                return response;
            }
            response.dns_records = response.dns_records.length ? response.dns_records : aRecords;
            return response;
        });
    });
    */

  // temporary for valid emails without dns check
  response.valid_dns_records = true;
  return response;
};

export default validateEmailLocal;
