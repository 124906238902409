import React from 'react';
import PropTypes from 'prop-types';
import withDisclosureControl from '../../../shared/components/controls/DisclosureControlHOC';
import InfoIcon from '../../../shared/icons/icon-info.svg';
import ArrowDown from '../../../shared/icons/icon-arrow-down.svg';


const NavBarInfoTrigger = ({ name, isOpen }) => (
  <span className="nav-bar-info__trigger">
    <InfoIcon className="nav-bar-info__info-icon" />
    {name}
    <ArrowDown className={`${isOpen ? 'nav-bar-info__arrow-icon nav-bar-info__arrow-icon--open' : 'nav-bar-info__arrow-icon'}`} />
  </span>
);

const NavBarInfo = ({
  id,
  items,
  setIsOpen,
}) => {
  const handleEscape = ({ key }) => key === 'Escape' && setIsOpen(false);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div id={id} onKeyUp={handleEscape}>
      <ul className="nav-bar-info">
        {items.map(item => (
          <li
            key={`${item.id}-key`}
          >
            <a
              className="nav-bar-info__link"
              href={item.url}
            >
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

NavBarInfo.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

NavBarInfoTrigger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

export default withDisclosureControl(
  NavBarInfo,
  NavBarInfoTrigger,
);
