import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from '@uplight/marketplace-forms';
import { Checkbox, CustomReactMarkdown } from '@uplight/marketplace-components';
import FormContainer from '../../../shared/components/FormContainer/FormContainer';
import getValueFromWindow from '../../../shared/helpers/getValueFromWindow';
import { controlsRendererMapSurvey } from '../Eligibility/formConfig';
import Analytics from '../../../analytics/GoogleAnalytics';
import { ProgramEnrollmentCompleted } from '../../analytics/events';


class Enrollment extends Component {
  constructor(props) {
    super(props);

    this.setCommitment = this.setCommitment.bind(this);
    this.submit = this.submit.bind(this);
    this.tenantDisplayName = getValueFromWindow('globals', 'tenant').displayName;
    this.googleAnalytics = new Analytics();
  }

  componentDidMount() {
    const {
      rebateCommitments,
      reportFormValues,
      surveyQuestionsForm,
      onSubmitHandler,
    } = this.props;

    if (rebateCommitments.length > 0) {
      reportFormValues({ isValid: false, values: [] }, 'commitments');
      this.setState({
        commitmentValues: [],
      });
    }

    if (surveyQuestionsForm.length === 0) {
      onSubmitHandler();
    }
  }

  setCommitment(updateKey) {
    const { rebateCommitments, reportFormValues } = this.props;
    const { commitmentValues } = this.state;
    const values = [];
    const selected = commitmentValues.includes(updateKey);
    let isValid = !selected;

    rebateCommitments.forEach(({ form }) => {
      form.forEach(({ fieldKey }) => {
        if (
          (!selected && fieldKey === updateKey) ||
          (fieldKey !== updateKey && commitmentValues.includes(fieldKey))
        ) {
          values.push(fieldKey);
        } else {
          isValid = false;
        }
      });
    });

    this.setState({ commitmentValues: values });
    reportFormValues({ isValid, values }, 'commitments');
  }

  submit() {
    const {
      surveyQuestionsForm,
      onQueueComplete,
      onSubmitHandler,
    } = this.props;

    if (surveyQuestionsForm.length === 0) {
      onQueueComplete();
    }

    this.googleAnalytics.trackEvent(ProgramEnrollmentCompleted);
    onSubmitHandler();
  }

  render() {
    const {
      surveyQuestionsForm,
      rebateCommitments,
      programs,
      reportFormValues,
      validateControls,
      onQueueComplete,
      inFlight,
      showInvalidFormMessage,
    } = this.props;
    const { state } = this;

    return (
      <div className="enrollment__container">
        <div className="order-process-step__header">Finish up your program enrollment</div>
        <div className="order-process-step__description">
          <p>Fill out this page to finish applying for the free product and program enrollment.</p>
          <p>
            {`This helps us to keep ${this.tenantDisplayName} records up to date so we can identify future opportunities for you to save.`}
          </p>
        </div>
        {surveyQuestionsForm.length > 0 && (
          <>
            <div className="enrollment__header">Next steps</div>
            <FormContainer title="Required Questions">
              <Form
                formData={surveyQuestionsForm}
                reportFormValues={form => reportFormValues(form, 'survey')}
                validateControls={validateControls}
                controlsRendererMap={controlsRendererMapSurvey}
                onQueueComplete={onQueueComplete}
              />
            </FormContainer>
          </>
        )}
        {state && rebateCommitments.length > 0 && (
          <>
            <div className="enrollment__header">Confirm a few things about your enrollment participation</div>
            {rebateCommitments.map(({ eligibilityId, form, programId }) => {
              const program = programs.find(({ id }) => (id === programId));
              const learnMoreContent = `[${program.content.moreInfoText}](${program.content.moreInfoLinkTo}{openNewTab})`;

              return (
                <FormContainer title={program.content.title} key={eligibilityId}>
                  <CustomReactMarkdown
                    className="enrollment__commitment-cta"
                    linkMeta={['allowIcon']}
                    source={learnMoreContent}
                    renderers={{
                      paragraph: 'span',
                    }}
                  />
                  {form.map(field => (
                    <Checkbox
                      key={field.fieldKey}
                      className="commitments-form__checkbox"
                      id={field.fieldKey}
                      name={field.fieldKey}
                      isSelected={state.commitmentValues.includes(field.fieldKey)}
                      onChange={() => this.setCommitment(field.fieldKey)}
                    >
                      <CustomReactMarkdown
                        linkMeta={['allowIcon']}
                        source={field.label}
                        renderers={{
                          paragraph: 'span',
                        }}
                      />
                    </Checkbox>
                  ))}
                </FormContainer>
              );
            })}
          </>
        )}
        {showInvalidFormMessage && (
          <div className="text-input-control__error--single-use">Please complete all of the required fields above</div>
        )}
        <button type="button" onClick={this.submit} className="enrollment-form__submit-btn">
          Finish &amp; go to shipping
          {inFlight && <span className="button-loader" />}
        </button>
      </div>
    );
  }
}

Enrollment.propTypes = {
  surveyQuestionsForm: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rebateCommitments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  programs: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired,
  inFlight: PropTypes.bool.isRequired,
  reportFormValues: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  validateControls: PropTypes.string.isRequired,
  onQueueComplete: PropTypes.func.isRequired,
  showInvalidFormMessage: PropTypes.bool.isRequired,
};

export default Enrollment;
