import React from 'react';
import PropTypes from 'prop-types';
import ReadOnlyShippingAddress from './ReadOnlyShippingAddress';


const OrderShipping = function OrderShippingComponent({
  selectedShippingMethod,
  shippingAddress,
  contactInfo,
}) {
  return (
    <div className="order-shipping">
      <div className="order-shipping__title">Shipping</div>
      <div className="order-shipping__sent-to">
        {`Items sent via ${selectedShippingMethod.displayName} to:`}
      </div>
      <ReadOnlyShippingAddress shippingAddress={shippingAddress} />
      <div className="order-shipping__confirmation">
        Confirmation and shipping updates will be sent to:
        <span className="order-shipping__email">{contactInfo.email}</span>
      </div>
    </div>
  );
};

OrderShipping.propTypes = {
  selectedShippingMethod: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
  }).isRequired,
  shippingAddress: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    stateCode: PropTypes.string.isRequired,
  }).isRequired,
  contactInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrderShipping;
