/* eslint-disable max-params */
import React from 'react';
import PropTypes from 'prop-types';
import findControl from '@uplight/marketplace-forms/Forms/helpers/findControl';
import { SubmissionError } from '@uplight/marketplace-components';
import { controlsRendererMap } from './formConfig';
import getSubmissionErrorMessage from './helpers/getSubmissionErrorMessage';
import getValueFromWindow from '../../../shared/helpers/getValueFromWindow';
import ValidationInfo from './ValidationInfo';
import { RaasEligibilityEnum } from '../../../../config/constants';


const AccountForm = function AccountForm(
  formData,
  controls,
  reportControlValues,
  classPrefixOverride,
  validateControls,
  passThroughProps,
) {
  const firstName = findControl.byFieldKey(controls, 'identity_validation.secondary.first_name');
  const lastName = findControl.byFieldKey(controls, 'identity_validation.secondary.last_name');
  const accountNumber = findControl.byFieldKey(controls, 'identity_validation.secondary.account_number');
  const [{ instructions }] = formData;


  const {
    raasEligibilityStatus,
    resetEligibility,
    validationMethod,
  } = passThroughProps;
  const tenant = getValueFromWindow('globals', 'tenant');

  return (
    <>
      <ValidationInfo
        tenant={tenant.displayName}
        instructions={instructions}
        validationMethod="account"
      />
      { accountNumber && (
        <fieldset className="identity-form__fieldset">
          { firstName && lastName && (
            <div className="identity-form__row">
              <div className="identity-form__field--half">
                <firstName.Control
                  fieldData={firstName.fieldData}
                  fieldId={firstName.fieldId}
                  reportControlValues={reportControlValues}
                  validateControls={validateControls}
                  customControlRenderer={controlsRendererMap[firstName.fieldData.control.type]}
                />
              </div>
              <div className="identity-form__field--half">
                <lastName.Control
                  fieldData={lastName.fieldData}
                  fieldId={lastName.fieldId}
                  reportControlValues={reportControlValues}
                  validateControls={validateControls}
                  customControlRenderer={controlsRendererMap[lastName.fieldData.control.type]}
                />
              </div>
            </div>
          )}
          <div className="identity-form__row">
            <div className="identity-form__field">
              <accountNumber.Control
                fieldData={accountNumber.fieldData}
                fieldId={accountNumber.fieldId}
                reportControlValues={reportControlValues}
                validateControls={validateControls}
                customControlRenderer={controlsRendererMap[accountNumber.fieldData.control.type]}
              />
            </div>
          </div>
          { !resetEligibility &&
            raasEligibilityStatus === RaasEligibilityEnum.ACCOUNT_NOT_FOUND && (
            <div className="identity-errors">
              <SubmissionError message={getSubmissionErrorMessage(validationMethod)} />
            </div>
          )}
        </fieldset>
      )}
    </>
  );
};

AccountForm.propTypes = {
  controls: PropTypes.shape({}).isRequired,
  passThroughProps: PropTypes.shape({
    tenant: PropTypes.string.isRequired,
    submissionErrorMessage: PropTypes.string,
    submissionResult: PropTypes.string,
    showSubmissionErrorMessage: PropTypes.bool,
  }).isRequired,
};


export default AccountForm;
