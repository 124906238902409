import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from '@uplight/marketplace-forms';
import { SubmissionError } from '@uplight/marketplace-components';
import addressFormRenderer from './AddressForm';
import accountFormRenderer from './AccountForm';
import { controlsRendererMap, controlsRendererMapSurvey } from './formConfig';
import ActionControls from './ActionControls';
import getValueFromWindow from '../../../shared/helpers/getValueFromWindow';
import { RaasEligibilityEnum } from '../../../../config/constants';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import TermsAndConditionsForm from './TermsAndConditions/TermsAndConditionsForm';
import FormContainer from '../../../shared/components/FormContainer/FormContainer';


class Eligibility extends Component {
  constructor(props) {
    super(props);
    this.tenant = getValueFromWindow('globals', 'tenant');
    this.state = {
      showAddressInfo: false,
    };
    this.toggleAddressInfo = this.toggleAddressInfo.bind(this);
  }

  getCustomRenderer() {
    const { validationMethod } = this.props;

    if (validationMethod === 'primary') {
      return addressFormRenderer;
    }

    return accountFormRenderer;
  }

  toggleAddressInfo() {
    const { showAddressInfo } = this.state;

    this.setState({
      showAddressInfo: !showAddressInfo,
    });
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      formData,
      instructions,
      reportFormValues,
      validationMethod,
      setValidationMethod,
      inFlight,
      showInvalidFormMessage,
      onSubmitHandler,
      validateControls,
      onQueueComplete,
      raasEligibilityStatus,
      raasProgramMessage,
      resetEligibility,
      termsForm,
      termsItemized,
      termsAgreement,
      handleTermsAgreement,
      showTermsMessage,
      eligibilityQuestionsForm,
      product,
    } = this.props;

    const { showAddressInfo } = this.state;
    const passThroughProps = {
      toggleAddressInfo: this.toggleAddressInfo,
      instructions,
      showAddressInfo,
      raasEligibilityStatus,
      resetEligibility,
      validationMethod,
    };

    const showRaasEligibilityStatus = raasEligibilityStatus === RaasEligibilityEnum.NOT_ELIGIBLE;
    const programDescription = product.addOns && product.addOns.length > 0 ? ' and program enrollment' : '';
    const description = `Verify your ${this.tenant.displayName} account information and fill out the rest of this page to check your eligibility for the free product${programDescription}.`;

    return (
      <div className="eligibility__container">
        <p className="order-process-step__header">Check your eligibility</p>
        <p className="order-process-step__description">{description}</p>
        <Form
          formData={formData}
          reportFormValues={form => reportFormValues(form, 'account')}
          controlsRendererMap={controlsRendererMap}
          customRenderer={this.getCustomRenderer()}
          onQueueComplete={onQueueComplete}
          passThroughProps={passThroughProps}
          validateControls={validateControls}
        />
        <ActionControls
          setValidationMethod={setValidationMethod}
          validationMethod={validationMethod}
          utilityName={this.tenant.displayName}
        />
        {showInvalidFormMessage && (
          <div className="text-input-control__error--single-use">Please complete all of the required fields above</div>
        )}
        {eligibilityQuestionsForm && eligibilityQuestionsForm.length > 0 && (
          <>
            <h2 className="terms-and-conditions__section-title">Answer a few questions</h2>
            <FormContainer title="Required Questions">
              <Form
                formData={eligibilityQuestionsForm}
                reportFormValues={form => reportFormValues(form, 'eligibility')}
                validateControls={validateControls}
                controlsRendererMap={controlsRendererMapSurvey}
                onQueueComplete={onQueueComplete}
              />
            </FormContainer>
          </>
        )}
        {termsItemized && termsItemized.length > 0 && (
          <TermsAndConditions termsItemized={termsItemized} />
        )}
        {termsForm && termsForm.length > 0 && (
          <FormContainer title="To Move Forward">
            <TermsAndConditionsForm
              showTermsMessage={showTermsMessage}
              termsAgreement={termsAgreement}
              handleTermsAgreement={handleTermsAgreement}
            />
            <button type="button" onClick={onSubmitHandler} className="eligibility-form__submit-btn">
              Agree &amp; continue
              {inFlight && <span className="button-loader" />}
            </button>
          </FormContainer>
        )}
        {showRaasEligibilityStatus && (
          <div className="identity-errors">
            <SubmissionError message={raasProgramMessage} />
          </div>
        )}
      </div>
    );
  }
}

Eligibility.propTypes = {
  formData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  instructions: PropTypes.string,
  inFlight: PropTypes.bool.isRequired,
  reportFormValues: PropTypes.func.isRequired,
  validationMethod: PropTypes.string.isRequired,
  setValidationMethod: PropTypes.func.isRequired,
  showInvalidFormMessage: PropTypes.bool.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  validateControls: PropTypes.string.isRequired,
  onQueueComplete: PropTypes.func.isRequired,
  raasEligibilityStatus: PropTypes.string.isRequired,
  raasProgramMessage: PropTypes.string.isRequired,
  resetEligibility: PropTypes.bool.isRequired,
  eligibilityQuestionsForm: PropTypes.arrayOf(PropTypes.shape({})),
  termsForm: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  termsItemized: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  termsAgreement: PropTypes.bool.isRequired,
  handleTermsAgreement: PropTypes.func.isRequired,
  showTermsMessage: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    addOns: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

Eligibility.defaultProps = {
  eligibilityQuestionsForm: [],
  instructions: '',
};

export default Eligibility;
