import { RECEIVE_ERROR, CLEAR_ERRORS } from '../actions/errors';


export default function errorsReducer(state = [], action) {
  switch (action.type) {
    case RECEIVE_ERROR:
      return state.concat([action.error]);
    case CLEAR_ERRORS:
      return [];
    default:
      return state;
  }
}
