import React from 'react';
import PropTypes from 'prop-types';
import IconWarning from '../../../shared/icons/icon-warning.svg';
import createUniqueId from '../../../shared/helpers/createUniqueId';


const Disclaimer = function DisclaimerComponent({
  title,
  tasksTitle,
  tasks,
  note,
}) {
  return (
    <div className="disclaimer">
      <div className="disclaimer__title">
        <IconWarning className="disclaimer__icon" />
        {title}
      </div>
      {tasksTitle}
      <ol className="disclaimer__requirements">
        {tasks.map(task => (
          <li key={createUniqueId(task)}>
            {task}
          </li>
        ))}
      </ol>
      <div className="disclaimer__entry-body">
        <p>
          {note.prefix}
          <a href={note.linkUrl}>
            {note.linkText}
          </a>
          {note.suffix}
        </p>
      </div>
    </div>
  );
};

Disclaimer.propTypes = {
  title: PropTypes.string,
  tasksTitle: PropTypes.string,
  tasks: PropTypes.arrayOf(PropTypes.string),
  note: PropTypes.shape({
    prefix: PropTypes.string,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string,
    suffix: PropTypes.string,
  }),
};

Disclaimer.defaultProps = {
  title: '',
  tasksTitle: '',
  tasks: [],
  note: {
    prefix: '',
    linkText: '',
    linkUrl: '',
    suffix: '',
  },
};

export default Disclaimer;
