import checkoutModel from './models/checkoutModel';


export default class Analytics {
  constructor() {
    this.dataLayer = window.dataLayer;
    this.gaEnabled = this.gaEnabled.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.pushToDataLayer = this.pushToDataLayer.bind(this);
  }

  checkoutProcess(product, step) {
    if (!product) {
      return false;
    }

    return this.pushToDataLayer(
      checkoutModel(product, step),
    );
  }

  trackEvent(customEvent) {
    if (customEvent) {
      return this.pushToDataLayer({ event: 'trackEvent', ...customEvent });
    }

    return false;
  }

  pushToDataLayer(ecommMeasurement) {
    let timer = 0;
    const timerCeiling = 2000;
    const interval = 250;
    const hasGoogleAnalytics = setInterval(() => {
      timer += interval;
      if (this.gaEnabled()) {
        this.dataLayer.push(ecommMeasurement);
        clearInterval(hasGoogleAnalytics);
      }
      if (timer >= timerCeiling) {
        clearInterval(hasGoogleAnalytics);
      }
    }, interval);
  }

  gaEnabled() {
    this.dataLayer = window.dataLayer;
    return this.dataLayer;
  }
}
