import ComponentLoader from './lib/ComponentLoader';
import ErrorMessage from './Error/ErrorMessage';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Product from './Product/Product';
import Program from './Program/Program';
import Details from './Product/Details';
import OrderProcess from './OrderProcess/container/OrderProcess';
import Page from './Page/Page';


const componentLoader = new ComponentLoader([
  ErrorMessage,
  Header,
  Footer,
  Product,
  Program,
  Details,
  OrderProcess,
  Page,
]);

componentLoader.load();
