export default function getOrderItemsFromProduct({
  name: productName,
  subtitle,
  image,
}) {
  return [
    {
      productName: `${productName} ${subtitle}`,
      images: {
        small: [
          {
            alt: productName,
            url: image.url,
          },
        ],
      },
      variationAttributes: [],
      price: {
        sales: {
          formatted: 'Free',
        },
      },
      quantity: 1,
      options: [],
      discounts: {},
      discount: {
        discounts: [],
      },
      rebatesApplied: {
        rebates: [],
      },
      linePrice: 'Free',
      bonusProducts: [],
    },
  ];
}
