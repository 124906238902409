import UniversalCookie from 'universal-cookie';


const fetchOptions = function getFetchOptions(type, payload) {
  const univCookie = new UniversalCookie();
  const formData = new FormData();
  const ssidCookie = univCookie.get('ssid');

  switch (type) {
    case 'post':
      return {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': ssidCookie,
        },
        body: JSON.stringify(payload),
        credentials: 'same-origin',
      };
    case 'postForm':
      Object.keys(payload).forEach(key => formData.append(key, payload[key]));
      return {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': ssidCookie,
        },
        body: formData,
        credentials: 'same-origin',
      };
    default:
      return {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': ssidCookie,
        },
        credentials: 'same-origin',
      };
  }
};

export default fetchOptions;
