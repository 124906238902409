import OrderProcessActionType from '../actions/types';


export default function orderProcessReducer(state = {}, action) {
  switch (action.type) {
    case OrderProcessActionType.RESET_ELIGIBILITY:
      return {
        ...state,
        resetEligibility: true,
      };
    case OrderProcessActionType.SUBMITTING_FORM:
      return {
        ...state,
        inFlight: true,
      };
    case OrderProcessActionType.RECEIVE_FORM_SUBMISSION_RESPONSE:
      return {
        ...state,
        inFlight: false,
        response: action.response,
        resetEligibility: false,
      };
    case OrderProcessActionType.VALIDATE_EMAIL:
      return {
        ...state,
      };
    case OrderProcessActionType.RECEIVE_EMAIL_VALIDATION_RESPONSE:
      return {
        ...state,
        emailResponse: action.response,
      };
    default:
      return state;
  }
}
