import { CustomFormFieldRenderers } from '@uplight/marketplace-components';


export const fieldTypes = {
  'identity_validation.primary.first_name': 'text',
  'identity_validation.primary.last_name': 'text',
  'identity_validation.primary.address_line_1': 'text',
  'identity_validation.primary.address_line_2': 'text',
  'identity_validation.primary.address_zip_code': 'text',
  'identity_validation.primary.address_city': 'text',
  'identity_validation.primary.address_country': 'select',
  'identity_validation.primary.address_state': 'select',
  'identity_validation.secondary.account_number': 'text',
};

export const controlsRendererMap = {
  radio: CustomFormFieldRenderers.CustomRadioRenderer,
  select: CustomFormFieldRenderers.CustomDropdownRenderer,
  text: CustomFormFieldRenderers.CustomTextInputRenderer,
  email: CustomFormFieldRenderers.CustomTextInputRenderer,
  tel: CustomFormFieldRenderers.CustomTextInputRenderer,
};

export const controlsRendererMapSurvey = {
  radio: CustomFormFieldRenderers.CustomRadioRendererSurvey,
  select: CustomFormFieldRenderers.CustomDropdownRendererSurvey,
  text: CustomFormFieldRenderers.CustomTextInputRenderer,
  email: CustomFormFieldRenderers.CustomTextInputRenderer,
  tel: CustomFormFieldRenderers.CustomTextInputRenderer,
};

export const restrictedFields = {
  default: [
    'country',
  ],
  readOnlyAddress: [
    'identity_validation.primary.first_name',
    'identity_validation.primary.last_name',
    'identity_validation.primary.address_line_1',
    'identity_validation.primary.address_line_2',
    'identity_validation.primary.address_zip_code',
    'identity_validation.primary.address_city',
    'identity_validation.primary.address_state',
  ],
};
