const getAddressFormInstructions = function getAddressFormInstructions(response) {
  const forms = response.identity_validation.form;

  if (!forms) {
    return '';
  }

  const primaryForm = forms.find(form => form.formKey === 'primary');

  if (!primaryForm || !primaryForm.instructions) {
    return '';
  }

  return primaryForm.instructions;
};

export default getAddressFormInstructions;
