/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import LocationIcon from '../../../shared/icons/icon-location.svg';


const ShippingInfo = function ShippingAddressComponent({
  rebateAddress,
  utilityName,
}) {
  const address2 = rebateAddress.address2 ? ` ${rebateAddress.address2}` : '';

  return (
    <div className="shipping-info">
      <p className="shipping-info__copy">
        {`
          To prevent fraudulent rebate claims, you cannot modify your shipping address.
          The service address tied to your ${utilityName} account must match your shipping address.
        `}
      </p>
      <div className="shipping-info__address">
        <h2 className="shipping-info__address-title">Items will be sent to:</h2>
        <div className="shipping-info__address-box">
          <LocationIcon className="shipping-info__address-box__icon" />
          <span className="shipping-info__address-box__copy">
            {`${rebateAddress.first_name} ${rebateAddress.last_name}`}
          </span>
          <span className="shipping-info__address-box__copy">
            {`${rebateAddress.address1}${address2}`}
          </span>
          <span className="shipping-info__address-box__copy">
            {`${rebateAddress.city}, ${rebateAddress.state_code} ${rebateAddress.postal_code}`}
          </span>
        </div>
      </div>
    </div>
  );
};

ShippingInfo.propTypes = {
  rebateAddress: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    postal_code: PropTypes.string.isRequired,
    state_code: PropTypes.string.isRequired,
  }).isRequired,
  utilityName: PropTypes.string.isRequired,
};


export default ShippingInfo;
