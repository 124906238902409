import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getErrorContent from './helpers/getErrorContent';
import getValueFromWindow from '../shared/helpers/getValueFromWindow';


class ErrorMessage extends Component {
  static isUserBlockingOnError() {
    return false;
  }

  static getDomId() {
    return 'error_message';
  }

  render() {
    const { status } = this.props;

    const tenant = getValueFromWindow('globals', 'tenant');
    const { title, body } = getErrorContent(status, tenant.displayName);

    return (
      <div className="error-wrapper">
        <div className="error-title">
          {title}
        </div>
        <div className="error-body">
          {body}
        </div>
      </div>
    );
  }
}

ErrorMessage.propTypes = {
  status: PropTypes.number,
};

ErrorMessage.defaultProps = {
  status: null,
};

export default ErrorMessage;
