import React from 'react';
import PropTypes from 'prop-types';
import { CustomReactMarkdown } from '@uplight/marketplace-components';


const FormContainer = ({ title, children }) => (
  <div className="form-container">
    <div className="form-container__header">
      <h3 className="form-container__header-title">
        <CustomReactMarkdown source={title} noWrapper />
      </h3>
    </div>
    <div className="form-container__content">
      {children}
    </div>
  </div>
);

FormContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default FormContainer;
