import Bootstrap from './Bootstrap';
import packageJson from '../../../package.json';


class ComponentLoader {
  constructor(componentList) {
    this.componentList = componentList;

    /* eslint-disable-next-line no-console */
    console.log(`Free Products Components version: ${packageJson.version}`);
  }

  load() {
    this.componentList.forEach((Component) => {
      new Bootstrap(Component).init();
    });
  }
}

export default ComponentLoader;
