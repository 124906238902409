import React from 'react';
import PropTypes from 'prop-types';
import { OrderItems } from '@uplight/marketplace-components';
import OrderShipping from './OrderShipping';
import getValueFromWindow from '../../../shared/helpers/getValueFromWindow';
import getOrderItemsFromProduct from './helpers/getOrderItemsFromProduct';
import IconEmail from '../../../shared/icons/icon-email.svg';
import IconPhone from '../../../shared/icons/icon-phone.svg';
import IconLink from '../../../shared/icons/icon-link.svg';
import Disclaimer from './Disclaimer';


const CheckoutConfirmation = function CheckoutConfirmationComponent({
  orderNumber,
  selectedShippingMethod,
  shippingAddress,
  contactInfo,
  product,
  offers,
  trackBrowseMarketplace,
}) {
  const tenant = getValueFromWindow('globals', 'tenant');
  const items = getOrderItemsFromProduct(product);

  return (
    <div className="order-process-step__container">
      <p className="order-process-step__header">Thank you for your order!</p>
      <div className="checkout-confirmation__order-number">
        <span className="checkout-confirmation__order-number-text">
          Order number:
        </span>
        #
        {orderNumber}
      </div>
      <div className="checkout-confirmation__items__container">
        <div className="checkout-confirmation__section-title">
          {`Items (${items.length})`}
        </div>
        <OrderItems items={items} />
      </div>
      <OrderShipping
        selectedShippingMethod={selectedShippingMethod}
        shippingAddress={shippingAddress}
        contactInfo={contactInfo}
      />
      <div className="order-process-step__horizontal-divider" />
      {offers.disclaimer && (
        <>
          <Disclaimer {...offers.disclaimer} />
          <div className="order-process-step__horizontal-divider" />
        </>
      )}
      {tenant.receiptContinueUrl && (
        <a href={tenant.receiptContinueUrl} className="checkout-confirmation__browse-button" onClick={trackBrowseMarketplace}>
          {`Browse the ${tenant.displayName} Marketplace`}
        </a>
      )}
      <div>
        <div className="checkout-confirmation__section-title">
          Contact Customer Support
        </div>
        <div className="checkout-confirmation__support-contact">
          <IconPhone className="checkout-confirmation__support-contact__icon" />
          <span className="checkout-confirmation__support-contact__label">
            Call
          </span>
          <a href={`tel:${tenant.supportInfo.phoneNumber}`}>
            {tenant.supportInfo.phoneNumber}
          </a>
        </div>
        <div className="checkout-confirmation__support-contact">
          <IconEmail className="checkout-confirmation__support-contact__icon" />
          <span className="checkout-confirmation__support-contact__label">
            Email
          </span>
          <a href={`mailto:${tenant.supportInfo.email}`}>
            {tenant.supportInfo.email}
          </a>
        </div>
        {tenant.supportInfo.linkUrl && (
        <div className="checkout-confirmation__support-contact">
          <IconLink className="checkout-confirmation__support-contact__icon" />
          <span className="checkout-confirmation__support-contact__label">
            Visit
          </span>
          <a href={`https://${tenant.supportInfo.linkUrl}`}>
            {tenant.supportInfo.linkUrl}
          </a>
        </div>
        )}
      </div>
    </div>
  );
};

CheckoutConfirmation.propTypes = {
  selectedShippingMethod: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
  }).isRequired,
  shippingAddress: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    stateCode: PropTypes.string.isRequired,
  }).isRequired,
  orderNumber: PropTypes.string.isRequired,
  contactInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
  }).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string,
    online: PropTypes.bool,
  }).isRequired,
  offers: PropTypes.shape({
    disclaimer: PropTypes.shape({
      title: PropTypes.string,
      tasksTitle: PropTypes.string,
      tasks: PropTypes.arrayOf(PropTypes.string),
      note: PropTypes.shape({
        prefix: PropTypes.string,
        linkText: PropTypes.string,
        linkUrl: PropTypes.string,
        suffix: PropTypes.string,
      }),
    }),
  }).isRequired,
  trackBrowseMarketplace: PropTypes.func.isRequired,
};

export default CheckoutConfirmation;
