import getValueFromWindow from '../../../../shared/helpers/getValueFromWindow';


const getSubmissionErrorMessage = function getSubmissionErrorMessage(validationMethod) {
  const { displayName } = getValueFromWindow('globals', 'tenant');

  switch (validationMethod) {
    case ('primary'):
      return `We couldn't find that account. Try using your ${displayName} account number to check your eligibility.`;
    case ('secondary'):
      return 'We couldn\'t find that account. Try using your service address to check your eligibility.';
    default:
      return null;
  }
};

export default getSubmissionErrorMessage;
