import React from 'react';
import PropTypes from 'prop-types';
import { FocusRing } from '@react-aria/focus';
import { Turn as Hamburger } from 'hamburger-react';


const HamburgerButton = ({ isOpen, toggleOpen }) => {
  const triggerText = isOpen ? 'close navigation drawer' : 'open navigation drawer';

  const handleKeyUp = ({ key }) => {
    if ((key === 'Escape' && isOpen) || key === ' ') {
      toggleOpen();
    }
  };

  return (
    <FocusRing focusRingClass="nav-drawer-button--focused">
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className="nav-drawer-button" onKeyUp={handleKeyUp}>
        <Hamburger
          toggle={toggleOpen}
          toggled={isOpen}
          direction="right"
          distance="lg"
          label={triggerText}
          hideOutline={false}
          duration={0.2}
        />
      </div>
    </FocusRing>
  );
};

HamburgerButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default HamburgerButton;
