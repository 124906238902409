import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getCurrentHub, BrowserClient, defaultIntegrations } from '@sentry/browser';
import { connect } from 'react-redux';
import { getErrors } from '../shared/redux/selectors/errors';
import Overlay from '../shared/components/Overlay/Overlay';
import ErrorMessage from '../Error/ErrorMessage';
import getValueFromWindow from '../shared/helpers/getValueFromWindow';
import packageJson from '../../../package.json';
import { REACT_SENTRY_DSN } from '../../config/constants';


const mapStateToProps = state => ({
  errors: getErrors(state),
});

class Boundary extends Component {
  constructor(props) {
    super(props);
    this.environment = getValueFromWindow('globals', 'environment');
    this.tenant = getValueFromWindow('globals', 'tenant');
    this.userAgent = navigator.userAgent;

    this.setupSentry();
  }

  componentDidUpdate(prevProps) {
    const { errors } = this.props;

    if (prevProps.errors !== errors && errors.length) {
      this.reportToUser(errors);
    }
  }

  setupSentry() {
    this.sentry = getCurrentHub();

    this.sentry.bindClient(
      new BrowserClient({
        debug: true,
        dsn: REACT_SENTRY_DSN,
        environment: this.environment,
        release: packageJson.version,
        integrations: defaultIntegrations,
        beforeSend(event) {
          if (event.extra && event.extra.componentId) {
            return event;
          }

          return null;
        },
      }),
    );
  }

  reportToUser(errors) { //eslint-disable-line
    const { blockUserOnError } = this.props;

    if (blockUserOnError) {
      /* do something */
    }
  }

  // TODO: Use static getDerivedStateFromError()
  componentDidCatch(error, errorInfo) {
    this.reportToUser(error);
    this.reportToSentry(error, errorInfo);
  }

  reportToSentry(error, errorInfo = {}) {
    const { componentId, blockUserOnError } = this.props;

    if (this.sentry) {
      this.sentry.withScope((scope) => {
        scope.setExtras({
          componentId,
          tenantId: this.tenant.id,
          url: location.href,
          userAgent: this.userAgent,
          blockUserOnError,
        });
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
        this.sentry.captureException(error);
      });
    }
  }

  render() {
    const {
      blockUserOnError, children, errors,
    } = this.props;

    if (blockUserOnError && errors.length > 0) {
      return (
        <Overlay>
          <ErrorMessage status={500} />
        </Overlay>
      );
    }

    return children;
  }
}

Boundary.propTypes = {
  componentId: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({})),
  blockUserOnError: PropTypes.bool.isRequired,
};

Boundary.defaultProps = {
  errors: undefined,
};

export default {
  withStore: connect(mapStateToProps)(Boundary),
  withoutStore: Boundary,
};
