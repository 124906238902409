import productModel from './productModel';


const checkoutModel = function googleAnalyticsProductCheckoutModel(product, step) {
  switch (step) {
    // The user is currently on the shipping step, and they have validated their eligibility.
    // This is considered the first step in the checkout process.
    case 'shipping': {
      return {
        event: 'begin_checkout',
        checkout: {
          actionField: { step },
          products: [productModel(product)],
        },
      };
    }
    // The user has completed the checkout process by submitting their shipping information.
    // This is considered the last step in the checkout process.
    case 'receipt': {
      return {
        event: 'add_shipping_info',
        checkout: {
          actionField: { step },
          products: [productModel(product)],
        },
      };
    }
    default: {
      return null;
    }
  }
};

export default checkoutModel;
