import React from 'react';
import PropTypes from 'prop-types';


const ActionControls = function ActionControlsComponent({
  setValidationMethod,
  validationMethod,
  utilityName,
}) {
  let callToAction;
  let alternateMethod;

  switch (validationMethod) {
    case 'primary':
      callToAction = `Enter your ${utilityName} account number`;
      alternateMethod = 'secondary';
      break;
    default:
      callToAction = `Enter your ${utilityName} service address`;
      alternateMethod = 'primary';
  }

  return (
    <div className="rebate-identity__alternate-method">
      Or choose another way to verify your account:
      <button
        className="rebate-identity__method-link"
        tabIndex={0}
        type="button"
        onClick={() => {
          setValidationMethod(alternateMethod);
        }}
      >
        {callToAction}
      </button>
    </div>
  );
};

ActionControls.propTypes = {
  setValidationMethod: PropTypes.func.isRequired,
  validationMethod: PropTypes.string.isRequired,
  utilityName: PropTypes.string.isRequired,
};

export default ActionControls;
