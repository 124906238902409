import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoIcon from '../../shared/icons/icon-info.svg';
import ToolsIcon from '../../shared/icons/icon-tools.svg';


const iconMap = {
  details: InfoIcon,
  install: ToolsIcon,
};

const Tabs = function TabsComponent({ tabs }) {
  const [activeLink, setActiveLink] = useState(null);
  const [selected, setSelected] = useState((tabs.find(tab => tab.selected) || tabs[0]).id);
  const handleClick = (event, id) => {
    event.preventDefault();
    setSelected(id);
  };
  const handleKeyup = (event, id) => {
    const { key } = event;

    event.preventDefault();

    if (key === 'ArrowRight') {
      const index = tabs.findIndex(tab => tab.id === id);

      if (index === tabs.length - 1) {
        setSelected(tabs[0].id);
      } else {
        setSelected(tabs[index + 1].id);
      }
    } else if (key === 'ArrowLeft') {
      const index = tabs.findIndex(tab => tab.id === id);

      if (index === 0) {
        setSelected(tabs[tabs.length - 1].id);
      } else {
        setSelected(tabs[index - 1].id);
      }
    }
  };

  useEffect(() => {
    /**
     * Because we never remove focus from the Tabs
     * this state is only used to indicate that we should focus the current tab
     * and after the first render it is reset back to null.
     * Later it can be set again when using keys to navigate the tabs.
     */
    setActiveLink(null);
  }, []);

  useEffect(() => {
    if (activeLink) {
      activeLink.focus();
    }
  }, [activeLink]);

  return (
    <>
      <ul className="tabs__handles" role="tablist">
        {tabs.map(({ id, handle }) => {
          const isCurrent = id === selected;
          const Icon = iconMap[id];

          return (
            <li role="presentation" key={id}>
              <a
                className={classNames('tabs__handle', { 'tabs__handle--active': isCurrent })}
                id={`tab_${id}`}
                href={`#tabpanel_${id}`}
                role="tab"
                aria-controls={`tab_${id}`}
                aria-selected={isCurrent}
                tabIndex={isCurrent ? 0 : -1}
                onClick={event => handleClick(event, id)}
                onKeyUp={event => handleKeyup(event, id)}
                ref={(link) => {
                  if (isCurrent) {
                    setActiveLink(link);
                  }
                }}
              >
                <Icon className="tabs__handle-icon" />
                {handle}
              </a>
            </li>
          );
        })}
      </ul>
      {tabs.map(({ id, content }) => {
        if (id !== selected) {
          return '';
        }

        return (
          <div
            id={`tabpanel_${id}`}
            key={id}
            className="tabs__panel"
            role="tabpanel"
            aria-labelledby={`tab_${id}`}
          >
            {content}
          </div>
        );
      })}
    </>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    handle: PropTypes.string,
    content: PropTypes.node,
  })).isRequired,
};

export default Tabs;
