const category = 'EligibilityCheck';


export const CheckEligibilityThroughAccountNumber = {
  category,
  action: 'ValidateByAccountNumber',
  label: 'Continue',
};

export const CheckEligibilityThroughServiceAddress = {
  category,
  action: 'ValidateByAddress',
  label: 'Continue',
};

export const FailedEligibilityAccountNotFound = {
  category,
  action: 'AccountNotFound',
  label: 'User Account Not Found',
};

export const FailedEligibilityNotEligible = {
  category,
  action: 'AccountNotEligible',
  label: 'User Account Not Eligible',
};

export const CheckEligibilityClicked = {
  category,
  action: 'CheckEligibilityClicked',
  label: 'Check Eligibility Clicked',
};

export const ProgramEnrollmentCompleted = {
  category: 'ProgramEnrollment',
  action: 'ProgramEnrollmentCompleted',
  label: 'Program Enrollment Completed',
};

export const CompleteOrderClicked = {
  category: 'OrderSubmission',
  action: 'CompleteOrderClicked',
  label: 'Complete Order Clicked',
};

export const OrderSubmitFailure = {
  category: 'OrderSubmission',
  action: 'OrderSubmitFailure',
  label: 'Order Submit Failure',
};

export const BrowseMarketplaceClicked = {
  category: 'BrowseMarketplace',
  action: 'BrowseMarketplaceClicked',
  label: 'Browse Marketplace Clicked',
};
