import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@uplight/marketplace-components';


const TermsAndConditionsForm = function TermsAndConditionsFormComponent({
  handleTermsAgreement,
  termsAgreement,
  showTermsMessage,
}) {
  return (
    <div className="terms-and-conditions-form">
      <Checkbox
        className="terms-and-conditions-form__checkbox"
        defaultSelected={termsAgreement}
        describedBy={showTermsMessage ? 'terms_and_conditions_form_error' : ''}
        onChange={checked => handleTermsAgreement({ target: { checked } })}
        required
      >
        I agree to all of the terms and conditions
      </Checkbox>
      {showTermsMessage && (
        <div
          id="terms_and_conditions_form_error"
          className="text-input-control__error--single-use"
          role="alert"
        >
          You must agree to the above in order to continue
        </div>
      )}
    </div>
  );
};

TermsAndConditionsForm.propTypes = {
  termsAgreement: PropTypes.bool.isRequired,
  handleTermsAgreement: PropTypes.func.isRequired,
  showTermsMessage: PropTypes.bool.isRequired,
};

export default TermsAndConditionsForm;
