/**
 * This function adds the query params from the current url to the endpoint
 * in order to keep the query params when interacting with an API.
 * @param {string} endpoint - The endpoint to add the query params to.
 * @returns {string} The endpoint with the query params.
 */
const addQueryParams = (endpoint) => {
  const currentUrl = new URL(window.location.href);
  const endpointUrl = new URL(endpoint);
  const queryParams = new URLSearchParams(currentUrl.search);

  queryParams.forEach((value, key) => {
    endpointUrl.searchParams.append(key, value);
  });

  return endpointUrl.toString();
};

export default addQueryParams;
