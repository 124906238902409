import { SiteFooter } from '@uplight/marketplace-components';
import React, { Component } from 'react';
import PropTypes from 'prop-types';


class Footer extends Component {
  static isUserBlockingOnError() {
    return false;
  }

  static getDomId() {
    return 'global_footer';
  }

  render() {
    return (
      <SiteFooter {...this.props} />
    );
  }
}

Footer.propTypes = {
  logo: PropTypes.shape({}).isRequired,
  categoryLinks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      display: PropTypes.string,
      external: PropTypes.bool,
      sort: PropTypes.number,
      url: PropTypes.string,
    })),
    sort: PropTypes.number,
    title: PropTypes.string,
  })).isRequired,
};

export default Footer;
