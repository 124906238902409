import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImageCarousel } from '@uplight/marketplace-components';
import priceFormatter from '../shared/helpers/priceFormatter';


class Product extends Component {
  static isUserBlockingOnError() {
    return false;
  }

  static getDomId() {
    return 'product';
  }

  render() {
    const {
      name,
      subtitle,
      price,
      image,
    } = this.props;

    return (
      <div className="product">
        <div className="product-details__header">
          <h1 className="product-details__title">{name}</h1>
          {subtitle &&
            (<div className="product-details__program-title">{subtitle}</div>)
          }
          <div className="product-details__retail">
            <span className="product-detail__retail-price-label product-detail__strike">
              {priceFormatter(price)}
              {' retail value'}
            </span>
          </div>
          <div className="product-details__pricing__total">
            <h2 className="product-details__total">
              Free
              <span className="product-details__total__disclaimer">
                <i> (No shipping or taxes)</i>
              </span>
            </h2>
          </div>
        </div>
        <div className="product-details__image-container">
          <ImageCarousel carouselImages={[image]} />
        </div>
      </div>
    );
  }
}

Product.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  image: PropTypes.shape({}).isRequired,
};

export default Product;
