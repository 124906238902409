import { CustomFormFieldRenderers } from '@uplight/marketplace-components';


// Type of input field needed for each attribute on the shipping form
export const fieldTypes = {
  'shipping_info.first_name': 'text',
  'shipping_info.last_name': 'text',
  'shipping_info.address_line_1': 'text',
  'shipping_info.address_line_2': 'text',
  'shipping_info.zip': 'text',
  'shipping_info.city': 'text',
  'shipping_info.country': 'select',
  'shipping_info.state_code': 'select',
  'shipping_info.email': 'email',
  'shipping_info.phone': 'tel',
  'shipping_info.shippingMethodID': 'radio',
};

export const controlsRendererMap = {
  radio: CustomFormFieldRenderers.CustomRadioRenderer,
  select: CustomFormFieldRenderers.CustomDropdownRenderer,
  text: CustomFormFieldRenderers.CustomTextInputRenderer,
  email: CustomFormFieldRenderers.CustomTextInputRenderer,
  tel: CustomFormFieldRenderers.CustomTextInputRenderer,
};

export const restrictedFields = {
  default: [
    'country',
  ],
  readOnlyAddress: [
    'shipping_info.first_name',
    'shipping_info.last_name',
    'shipping_info.address_line_1',
    'shipping_info.address_line_2',
    'shipping_info.zip',
    'shipping_info.city',
    'shipping_info.state_code',
  ],
};
