import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CustomReactMarkdown, InfoModal, AddOnIcon } from '@uplight/marketplace-components';
import Analytics from '../../../analytics/GoogleAnalytics';
import { CheckEligibilityClicked } from '../../analytics/events';


class Offers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };
    this.submit = this.submit.bind(this);
    this.googleAnalytics = new Analytics();
  }

  componentDidMount() {
    const { onMount } = this.props;

    onMount();
  }

  submit() {
    const { submitHandler } = this.props;

    this.googleAnalytics.trackEvent(CheckEligibilityClicked);
    submitHandler();
  }

  render() {
    const { description, modal } = this.props;
    const { modalIsOpen } = this.state;

    const linkActions = {
      openModal: () => this.setState({
        modalIsOpen: true,
      }),
    };

    const closeModal = () => this.setState({
      modalIsOpen: false,
    });

    return (
      <div className="order-process-step__container">
        <CustomReactMarkdown
          className="order-process__offers"
          linkActions={linkActions}
          linkMeta={['allowIcon', 'openNewTab']}
          source={description}
        />
        <button
          type="button"
          onClick={this.submit}
          className="order-process__offers-button"
        >
          Check eligibility
        </button>
        {modal && (
          <InfoModal
            styleFlag="addon"
            contentLabel="DR enrollment"
            headerIcon={<AddOnIcon type="dr" />}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            {...modal}
          />
        )}
      </div>
    );
  }
}

Offers.propTypes = {
  description: PropTypes.string.isRequired,
  modal: PropTypes.shape({
    header: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    subheader: PropTypes.string,
  }),
  onMount: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

Offers.defaultProps = {
  modal: null,
};

export default Offers;
