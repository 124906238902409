import { restrictedFields } from '../formConfig';


const filterValidFields = function filterValidFields(form, readOnlyAddress) {
  if (readOnlyAddress) {
    return form.filter(({ fieldKey }) => (!restrictedFields.readOnlyAddress.includes(fieldKey)));
  }

  return form;
};

export default filterValidFields;
