/* eslint-disable camelcase */
import React from 'react';
import findControl from '@uplight/marketplace-forms/Forms/helpers/findControl';
import { controlsRendererMap } from './formConfig';

/* eslint-disable-next-line max-params, complexity */
const shippingFormRenderer = function shippingFormRenderer(
  formData,
  controls,
  reportControlValues,
  classPrefixOverride,
  validateControls,
  passThroughProps,
) {
  if (!controls.length) {
    return null;
  }

  const firstName = findControl.byFieldKey(controls, 'shipping_info.first_name');
  const lastName = findControl.byFieldKey(controls, 'shipping_info.last_name');
  const address1 = findControl.byFieldKey(controls, 'shipping_info.address_line_1');
  const address2 = findControl.byFieldKey(controls, 'shipping_info.address_line_2');
  const city = findControl.byFieldKey(controls, 'shipping_info.city');
  const state_code = findControl.byFieldKey(controls, 'shipping_info.state_code');
  const zip = findControl.byFieldKey(controls, 'shipping_info.zip');
  const email = findControl.byFieldKey(controls, 'shipping_info.email');
  const phone = findControl.byFieldKey(controls, 'shipping_info.phone');
  const shippingMethod = findControl.byFieldKey(controls, 'shipping_info.shippingMethodID');
  const addressPresent = firstName && lastName && address1 && address2 && city && state_code && zip;
  const contactPresent = email && phone;

  return (
    <>
      {addressPresent && (
        <>
          <fieldset className="shipping-form__fieldset">
            <div className="identity-form__row">
              <div className="identity-form__field--half">
                <firstName.Control
                  fieldData={firstName.fieldData}
                  fieldId={firstName.fieldId}
                  reportControlValues={reportControlValues}
                  validateControls={validateControls}
                  customControlRenderer={controlsRendererMap[firstName.fieldData.control.type]}
                />
              </div>
              <div className="identity-form__field--half">
                <lastName.Control
                  fieldData={lastName.fieldData}
                  fieldId={lastName.fieldId}
                  reportControlValues={reportControlValues}
                  validateControls={validateControls}
                  customControlRenderer={controlsRendererMap[lastName.fieldData.control.type]}
                />
              </div>
            </div>
            <div className="identity-form__row">
              <div className="identity-form__field-street">
                <address1.Control
                  fieldData={address1.fieldData}
                  fieldId={address1.fieldId}
                  reportControlValues={reportControlValues}
                  validateControls={validateControls}
                  customControlRenderer={controlsRendererMap[address1.fieldData.control.type]}
                />
              </div>
              <div className="identity-form__field-apt">
                <address2.Control
                  fieldData={address2.fieldData}
                  fieldId={address2.fieldId}
                  reportControlValues={reportControlValues}
                  validateControls={validateControls}
                  customControlRenderer={controlsRendererMap[address2.fieldData.control.type]}
                />
              </div>
            </div>
            <div className="identity-form__row">
              <div className="identity-form__field--half">
                <city.Control
                  fieldData={city.fieldData}
                  fieldId={city.fieldId}
                  reportControlValues={reportControlValues}
                  customControlRenderer={controlsRendererMap[city.fieldData.control.type]}
                  validateControls={validateControls}
                />
              </div>
              <div className="identity-form__field--half">
                <state_code.Control
                  fieldData={state_code.fieldData}
                  fieldId={state_code.fieldId}
                  reportControlValues={reportControlValues}
                  customControlRenderer={controlsRendererMap[state_code.fieldData.control.type]}
                  validateControls={validateControls}
                />
              </div>
            </div>
            <div className="identity-form__row">
              <div className="identity-form__field--half">
                <zip.Control
                  fieldData={zip.fieldData}
                  fieldId={zip.fieldId}
                  reportControlValues={reportControlValues}
                  customControlRenderer={controlsRendererMap[zip.fieldData.control.type]}
                  validateControls={validateControls}
                />
              </div>
            </div>
          </fieldset>
          <div className="shipping-form__disclaimer-message">
            {`Your order can only be shipped to the state(s) where ${passThroughProps.utilityName} operates.
            It is not possible to deliver orders to P.O. boxes.`}
          </div>
          <div className="shipping-form__horizontal-divider" />
        </>
      )}
      {contactPresent && (
        <fieldset className="shipping-form__fieldset">
          <legend className="shipping-form__fieldset-legend">Enter your contact information</legend>
          <div className="identity-form__row">
            <div className="identity-form__field">
              <email.Control
                fieldData={email.fieldData}
                fieldId={email.fieldId}
                reportControlValues={reportControlValues}
                validateControls={validateControls}
                customControlRenderer={controlsRendererMap[email.fieldData.control.type]}
                customValidator={passThroughProps.customValidator}
              />
            </div>
          </div>
          <div className="identity-form__row">
            <div className="identity-form__field">
              <phone.Control
                fieldData={phone.fieldData}
                fieldId={phone.fieldId}
                reportControlValues={reportControlValues}
                validateControls={validateControls}
                customControlRenderer={controlsRendererMap[phone.fieldData.control.type]}
              />
            </div>
          </div>
        </fieldset>
      )}

      <fieldset className="shipping-form__fieldset">
        <legend className="shipping-form__fieldset-legend">Shipping method</legend>
        <shippingMethod.Control
          fieldData={shippingMethod.fieldData}
          fieldId={shippingMethod.fieldId}
          reportControlValues={reportControlValues}
          validateControls={validateControls}
          customControlRenderer={controlsRendererMap[shippingMethod.fieldData.control.type]}
        />
      </fieldset>
    </>
  );
};

export default shippingFormRenderer;
