import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContentComponents from '@uplight/marketplace-content';
import getValueFromWindow from '../shared/helpers/getValueFromWindow';
import Overlay from '../shared/components/Overlay/Overlay';
import ErrorMessage from '../Error/ErrorMessage';


class Page extends Component {
  static isUserBlockingOnError() {
    return false;
  }

  static getDomId() {
    return 'content_page';
  }

  constructor(props) {
    super(props);

    this.tenant = getValueFromWindow('globals', 'tenant');
    this.state = { error: '' };

    this.errorHandler = this.errorHandler.bind(this);
  }

  errorHandler(message) {
    this.setState({ error: message });
  }

  render() {
    const { contentId } = this.props;
    const { error } = this.state;

    // TODO: add proper error handling in Boundary component
    // Boundary component also needs to support non-redux components
    if (error) {
      return (
        <Overlay>
          <ErrorMessage status={500} />
        </Overlay>
      );
    }

    return (
      <ContentComponents.Page
        siteId={this.tenant.id}
        instanceId={contentId}
        errorHandler={this.errorHandler}
      />
    );
  }
}

Page.propTypes = {
  contentId: PropTypes.string.isRequired,
};

export default Page;
