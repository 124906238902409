export const RECEIVE_ERROR = 'freeProducts/errors/RECEIVE_ERROR';
export const CLEAR_ERRORS = 'freeProducts/errors/CLEAR_ERRORS';

export const receiveError = function receiveErrorAction(error) {
  const errorMessage = error.message;

  return {
    type: RECEIVE_ERROR,
    error: { message: errorMessage },
  };
};

export const clearErrors = function clearErrorsAction() {
  return {
    type: CLEAR_ERRORS,
  };
};
