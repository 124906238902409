export default function getErrorContent(status, displayName) {
  if (status === 404) {
    return {
      title: 'This page is not available',
      body: `Try refreshing the page.  If that doesn't work, check the link or go back to the ${displayName} Giveaway Hub.`,
    };
  }

  return {
    title: 'Something\'s broken right now - we’re working on a fix',
    body: 'In the meantime, try refreshing the page or check back later.',
  };
}
