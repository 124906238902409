import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import HamburgerButton from '../Navigation/NavDrawer/components/HamburgerButton';
import NavBar from '../Navigation/NavBar/NavBar';
import NavDrawer from '../Navigation/NavDrawer/NavDrawer';


class Header extends Component {
  static isUserBlockingOnError() {
    return false;
  }

  static getDomId() {
    return 'global_header';
  }

  constructor(props) {
    super(props);

    this.headerBottom = 0;
    this.headerRef = React.createRef();

    this.toggleMobileNavOpen = this.toggleMobileNavOpen.bind(this);

    this.state = {
      mobileNavIsOpen: false,
    };
  }

  toggleMobileNavOpen() {
    const { mobileNavIsOpen } = this.state;

    window.scrollTo(0, 0);

    if (this.headerRef.current) {
      this.headerBottom = this.headerRef.current.getBoundingClientRect().bottom;
    }

    document.body.classList.toggle('no-scroll', !mobileNavIsOpen);
    this.setState({ mobileNavIsOpen: !mobileNavIsOpen });
  }

  render() {
    const {
      logoUrl,
      siteName,
      navigation: {
        bar: navBar,
        drawer: navDrawer,
      },
    } = this.props;

    const {
      mobileNavIsOpen,
    } = this.state;

    return (
      <FocusTrap active={mobileNavIsOpen}>
        <div className="site-header" ref={this.headerRef}>
          <div className="site-header__utility-wrapper">
            <div className="site-header__utility">
              <div className="site-header__branding">
                <div className="site-header__logo-wrap">
                  <img
                    src={logoUrl}
                    alt={`${siteName} logo`}
                    className="site-header__logo"
                  />
                </div>
                {navDrawer.length > 0 && (
                  <HamburgerButton
                    isOpen={mobileNavIsOpen}
                    toggleOpen={this.toggleMobileNavOpen}
                  />
                )}
              </div>
            </div>
          </div>
          <NavDrawer
            isOpen={mobileNavIsOpen}
            items={navDrawer}
            toggleOpen={this.toggleMobileNavOpen}
            menuTop={this.headerBottom}
          />
          <NavBar {...navBar} />
        </div>
      </FocusTrap>
    );
  }
}

Header.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  siteName: PropTypes.string.isRequired,
  navigation: PropTypes.shape({
    bar: PropTypes.shape({}),
    drawer: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

Header.defaultProps = {
  navigation: {
    bar: {},
    drawer: [],
  },
};

export default Header;
