/* eslint-disable max-lines, camelcase, complexity */
import { v4 as uuid } from 'uuid';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { CustomReactMarkdown } from '@uplight/marketplace-components';
import FlowHeader from '../../FlowHeader/FlowHeader';
import errorsReducer from '../../shared/redux/reducers/errors';
import Shipping from '../components/Shipping/Shipping';
import animateScroll from '../../shared/helpers/animateScroll';
import CheckoutConfirmation from '../components/CheckoutConfirmation/CheckoutConfirmation';
import Eligibility from '../components/Eligibility/Eligibility';
import Enrollment from '../components/Enrollment/Enrollment';
import Survey from '../components/Survey/Survey';
import {
  submitStepFormsRequest,
  startSubmittingForm,
  resetEligibility,
  validateEmail,
} from '../actions/orderProcess';
import getValueFromWindow from '../../shared/helpers/getValueFromWindow';
import fetchOptions from '../../shared/helpers/fetchOptions';
import fetchData from '../../shared/helpers/fetchData';
import Overlay from '../../shared/components/Overlay/Overlay';
import Loader from '../../shared/components/Loader/Loader';
import Offers from '../components/Offers/Offers';
import orderProcessReducer from '../reducers/orderProcess';
import UnavailableProduct from '../components/UnavailableProduct/UnavailableProduct';
import addQueryParams from '../../shared/helpers/queryParams';
import Analytics from '../../analytics/GoogleAnalytics';
import {
  BrowseMarketplaceClicked,
  CheckEligibilityThroughAccountNumber,
  CheckEligibilityThroughServiceAddress,
} from '../analytics/events';
import {
  getCurrentStep,
  getAddressForm,
  getAccountForm,
  getInstructions,
  getShippingForm,
  getRaasEligibilityStatus,
  getRaasProgramMessage,
  getIsOrderLimitReached,
  getOrderSummary,
  getRebateAddress,
  getSubmitOrderFailure,
  getEmailValidity,
  getTermsAndConditionsForm,
  getTermsAndConditionsItemized,
  getEligibilityQuestionsForm,
  getSurveyQuestionsForm,
  getRebateCommitments,
  getFlowSteps,
} from '../selectors';


const mapStateToProps = state => ({
  currentStep: getCurrentStep(state),
  flowSteps: getFlowSteps(state),
  errors: state.errors,
  inFlight: state.orderProcess.inFlight,
  addressForm: getAddressForm(state),
  accountForm: getAccountForm(state),
  termsForm: getTermsAndConditionsForm(state),
  termsItemized: getTermsAndConditionsItemized(state),
  instructions: getInstructions(state),
  shippingForm: getShippingForm(state),
  eligibilityQuestionsForm: getEligibilityQuestionsForm(state),
  surveyQuestionsForm: getSurveyQuestionsForm(state),
  rebateCommitments: getRebateCommitments(state),
  orderSummary: getOrderSummary(state),
  rebateAddress: getRebateAddress(state),
  raasEligibilityStatus: getRaasEligibilityStatus(state),
  raasProgramMessage: getRaasProgramMessage(state),
  isOrderLimitReached: getIsOrderLimitReached(state),
  resetEligibility: state.orderProcess.resetEligibility,
  submitOrderFailure: getSubmitOrderFailure(state),
  emailValidity: getEmailValidity(state),
});

class OrderProcess extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      accountForm: accountFromProps,
      addressForm: addressFromProps,
      shippingForm: shippingFromProps,
    } = nextProps;

    const {
      accountForm: accountFromState,
      shippingForm: shippingFromState,
    } = prevState;

    if (accountFromProps.length && !accountFromState.length) {
      return {
        accountForm: accountFromProps,
        addressForm: addressFromProps,
      };
    }

    if (shippingFromProps.length && !shippingFromState.length) {
      return {
        shippingForm: shippingFromProps,
      };
    }

    return null;
  }

  static isUserBlockingOnError() {
    return true;
  }

  static getStore() {
    const initialState = {
      orderProcess: {
        inFlight: false,
        resetEligibility: false,
      },
      errors: [],
    };

    const middleware = [thunk];

    return createStore(
      combineReducers({
        orderProcess: orderProcessReducer,
        errors: errorsReducer,
      }),
      initialState,
      applyMiddleware(...middleware),
    );
  }

  static getDomId() {
    return 'order_process';
  }

  constructor(props) {
    super(props);
    this.state = {
      addressData: {},
      addressForm: [],
      accountData: {},
      accountForm: [],
      shippingData: {},
      shippingForm: [],
      eligibilityQuestionsData: [],
      surveyQuestionsData: null,
      rebateCommitmentsData: null,
      validationMethod: 'primary',
      showInvalidFormMessage: false,
      showTermsMessage: false,
      validateControls: '',
      doSubmit: false,
      termsAgreement: false,
    };

    this.timeoutId = null;
    this.googleAnalytics = new Analytics();
    this.tenant = getValueFromWindow('globals', 'tenant');
    this.setValidationMethod = this.setValidationMethod.bind(this);
    this.reportFormValues = this.reportFormValues.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onQueueComplete = this.onQueueComplete.bind(this);
    this.handleTermsAgreement = this.handleTermsAgreement.bind(this);
    this.baseUrl = getValueFromWindow('globals', 'baseUrl');
    this.apiEndpoint = addQueryParams(`${this.baseUrl}/api/process-order`);
    this.customValidator = this.customValidator.bind(this);
    this.trackBrowseMarketplace = this.trackBrowseMarketplace.bind(this);
  }

  componentDidMount() {
    const { dispatch, product: { id: productId, online }, programId } = this.props;

    if (!online) {
      return;
    }

    dispatch(
      submitStepFormsRequest(this.apiEndpoint, { programId, productId }),
    );

    // OCAPI JWT expires in 30 minutes
    const twentyFiveMinutes = 1500000;

    window.setInterval(() => {
      const options = fetchOptions('post', { programId, productId });

      fetchData(addQueryParams(`${this.baseUrl}/api/keep-alive`), options, {
        successHandler: () => {},
        errorHandler: () => {},
      });
    }, twentyFiveMinutes);
  }

  componentDidUpdate(prevProps) {
    const { currentStep, isOrderLimitReached } = this.props;

    if (isOrderLimitReached) {
      // reload tab after 10 seconds if order limit is reached
      const tenSeconds = 10000;

      this.timeoutId = setTimeout(() => {
        window.location.reload();
      }, tenSeconds);
    }

    if (currentStep !== prevProps.currentStep) {
      this.trackCheckoutProcess();
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  async onQueueComplete() {
    const {
      doSubmit,
      validationMethod,
    } = this.state;
    const { inFlight, product: { id: productId }, programId } = this.props;
    const { dispatch } = this.props;

    if (!doSubmit || inFlight) {
      return false;
    }

    this.setState({
      doSubmit: false,
    });

    const validForms = this.validateForms();
    const validTermsAndConditions = this.validateTermsAndConditions();
    const validEligibilityQuestions = this.validateEligibilityQuestions();
    const isInitialShippingForm = this.isInitialShippingForm();

    if (!validForms || !validTermsAndConditions || !validEligibilityQuestions) {
      this.setState({
        doSubmit: false,
        showInvalidFormMessage: !validForms && !isInitialShippingForm,
        showTermsMessage: !validTermsAndConditions,
      });

      return false;
    }

    const event = validationMethod === 'primary' ?
      CheckEligibilityThroughServiceAddress :
      CheckEligibilityThroughAccountNumber;

    this.googleAnalytics.trackEvent(event);

    await dispatch(startSubmittingForm());
    await dispatch(
      submitStepFormsRequest(
        this.apiEndpoint,
        {
          programId,
          productId,
          forms: this.getPayload(),
        },
      ),
    );

    return true;
  }

  onSubmitHandler() {
    const { inFlight } = this.props;

    if (inFlight) {
      return false;
    }

    this.setState({
      validateControls: uuid(),
      doSubmit: true,
    });

    return true;
  }

  getPayload() {
    const {
      accountData,
      addressData,
      shippingData,
      eligibilityQuestionsData,
      surveyQuestionsData,
      validationMethod,
      rebateCommitmentsData,
    } = this.state;
    const {
      currentStep,
      programId,
      product: { id: productId },
      termsForm,
    } = this.props;

    if (currentStep === 'offers') {
      return { advance: true };
    }

    if (currentStep === 'enrollment') {
      const payload = surveyQuestionsData ? { ...surveyQuestionsData.values } : {};

      rebateCommitmentsData.values.forEach((value) => {
        payload[value] = true;
      });

      return payload;
    }

    if (currentStep === 'survey') {
      return surveyQuestionsData.values;
    }

    if (currentStep === 'shipping') {
      return {
        ...shippingData.values,
        productId,
        programId,
      };
    }

    const termsAndConditions = termsForm.map(tnc => tnc.fieldKey).reduce(
      (tnc, agreement) => ({ ...tnc, [agreement]: true }),
      {},
    );

    switch (validationMethod) {
      case 'primary':
        return {
          ...addressData.values,
          ...eligibilityQuestionsData.values,
          ...termsAndConditions,
          identity_validation_method: validationMethod,
        };
      default:
        return {
          ...accountData.values,
          ...eligibilityQuestionsData.values,
          ...termsAndConditions,
          identity_validation_method: validationMethod,
        };
    }
  }

  async setValidationMethod(method) {
    const { dispatch } = this.props;

    this.setState({
      validationMethod: method,
      showInvalidFormMessage: false,
    }, animateScroll(0));

    await dispatch(
      resetEligibility(),
    );
  }

  getEligibilityForm() {
    const { validationMethod, accountForm, addressForm } = this.state;

    if (validationMethod === 'primary') {
      return addressForm;
    }

    return accountForm;
  }

  trackCheckoutProcess() {
    const { currentStep, product } = this.props;

    if (/(shipping|receipt)/.test(currentStep)) {
      this.googleAnalytics.checkoutProcess(product, currentStep.toLowerCase());
    }
  }

  handleTermsAgreement(event) {
    this.setState({
      termsAgreement: event.target.checked,
      showTermsMessage: !event.target.checked,
    });
  }

  reportFormValues(formData, formType) {
    const { validationMethod, showInvalidFormMessage } = this.state;
    const { currentStep } = this.props;
    const { isValid } = formData;

    if (isValid && showInvalidFormMessage) {
      this.setState({
        showInvalidFormMessage: false,
      });
    }

    if (currentStep === 'shipping') {
      this.setState({
        shippingData: formData,
      });
      return true;
    }

    if (formType === 'account') {
      switch (validationMethod) {
        case 'primary':
          this.setState({
            addressData: formData,
          });
          break;
        default:
          this.setState({
            accountData: formData,
          });
      }
    }

    if (formType === 'eligibility') {
      this.setState({
        eligibilityQuestionsData: formData,
      });
    }

    if (formType === 'survey') {
      this.setState({
        surveyQuestionsData: formData,
      });
    }

    if (formType === 'commitments') {
      this.setState({
        rebateCommitmentsData: formData,
      });
    }

    return true;
  }

  validateEligibilityQuestions() {
    const { eligibilityQuestionsData } = this.state;
    const { currentStep, eligibilityQuestionsForm } = this.props;

    if (currentStep !== 'eligibility') {
      return true;
    }

    if (!eligibilityQuestionsForm || eligibilityQuestionsForm.length === 0) {
      return true;
    }

    return eligibilityQuestionsData.isValid;
  }

  validateTermsAndConditions() {
    const { termsAgreement } = this.state;
    const { currentStep } = this.props;

    if (currentStep !== 'eligibility') {
      return true;
    }

    return termsAgreement;
  }

  validateForms() {
    const {
      validationMethod,
      accountData,
      addressData,
      shippingData,
      surveyQuestionsData,
      rebateCommitmentsData,
    } = this.state;
    const { currentStep } = this.props;

    if (currentStep === 'offers') {
      return true;
    }

    if (currentStep === 'enrollment') {
      return (!surveyQuestionsData || surveyQuestionsData.isValid) &&
        (!rebateCommitmentsData || rebateCommitmentsData.isValid);
    }

    if (currentStep === 'survey') {
      return surveyQuestionsData.isValid;
    }

    if (currentStep === 'shipping') {
      return shippingData.isValid;
    }

    switch (validationMethod) {
      case 'primary':
        return addressData.isValid;
      default:
        return accountData.isValid;
    }
  }

  isInitialShippingForm() {
    const {
      shippingData,
    } = this.state;
    const stateCode = this.tenant.state.code;

    return shippingData.values && Object.keys(shippingData.values).find(
      key => ([undefined, '001', stateCode].indexOf(shippingData.values[key]) === -1),
    ) === undefined;
  }

  async customValidator({ storedValue }) {
    const { dispatch } = this.props;

    await dispatch(validateEmail(storedValue));
    const { emailValidity: { isValid } } = this.props;

    return isValid;
  }

  trackBrowseMarketplace() {
    this.googleAnalytics.trackEvent(BrowseMarketplaceClicked);
  }

  render() {
    const {
      currentStep,
      flowSteps,
      termsForm,
      termsItemized,
      inFlight,
      raasEligibilityStatus,
      raasProgramMessage,
      isOrderLimitReached,
      resetEligibility,
      instructions,
      orderSummary,
      rebateAddress,
      submitOrderFailure,
      product,
      disclaimer,
      offers,
      eligibilityQuestionsForm,
      surveyQuestionsForm,
      rebateCommitments,
    } = this.props;

    const {
      validationMethod,
      showInvalidFormMessage,
      showTermsMessage,
      termsAgreement,
      validateControls,
      shippingForm,
    } = this.state;

    if (!product.online) {
      return (
        <div className="order-process__steps">
          <UnavailableProduct />
        </div>
      );
    }

    if (!currentStep) {
      return (
        <Overlay>
          <Loader />
        </Overlay>
      );
    }

    const showOffers = currentStep === 'offers';
    const showEligibility = currentStep === 'eligibility';
    const showSurvey = currentStep === 'survey';
    const showEnrollment = currentStep === 'enrollment';
    const showShipping = currentStep === 'shipping';
    const showOrderSummary = currentStep === 'receipt';

    return (
      <div className="order-process__steps">
        <FlowHeader steps={flowSteps} />
        {showOffers && (
          <Offers
            onMount={this.onSubmitHandler}
            submitHandler={this.onQueueComplete}
            tempAddOns={product.addOns}
            {...offers}
          />
        )}
        {showEligibility && (
          <Eligibility
            formData={this.getEligibilityForm()}
            instructions={instructions}
            onSubmitHandler={this.onSubmitHandler}
            reportFormValues={this.reportFormValues}
            validationMethod={validationMethod}
            setValidationMethod={this.setValidationMethod}
            showInvalidFormMessage={showInvalidFormMessage}
            showTermsMessage={showTermsMessage}
            validateControls={validateControls}
            onQueueComplete={this.onQueueComplete}
            inFlight={inFlight}
            raasEligibilityStatus={raasEligibilityStatus}
            raasProgramMessage={raasProgramMessage}
            resetEligibility={resetEligibility}
            termsForm={termsForm}
            termsItemized={termsItemized}
            termsAgreement={termsAgreement}
            handleTermsAgreement={this.handleTermsAgreement}
            eligibilityQuestionsForm={eligibilityQuestionsForm}
            product={product}
          />
        )}
        {showEnrollment && (
          <Enrollment
            surveyQuestionsForm={surveyQuestionsForm}
            rebateCommitments={rebateCommitments}
            programs={product.addOns}
            reportFormValues={this.reportFormValues}
            validateControls={validateControls}
            onQueueComplete={this.onQueueComplete}
            inFlight={inFlight}
            onSubmitHandler={this.onSubmitHandler}
            showInvalidFormMessage={showInvalidFormMessage}
          />
        )}
        {showSurvey && (
          <Survey
            surveyQuestionsForm={surveyQuestionsForm}
            reportFormValues={this.reportFormValues}
            validateControls={validateControls}
            onQueueComplete={this.onQueueComplete}
            inFlight={inFlight}
            onSubmitHandler={this.onSubmitHandler}
            showInvalidFormMessage={showInvalidFormMessage}
          />
        )}
        {showShipping && (
          <Shipping
            formData={shippingForm}
            onSubmitHandler={this.onSubmitHandler}
            reportFormValues={this.reportFormValues}
            showInvalidFormMessage={showInvalidFormMessage}
            rebateAddress={rebateAddress}
            validateControls={validateControls}
            customValidator={this.customValidator}
            onQueueComplete={this.onQueueComplete}
            inFlight={inFlight}
            resetEligibility={resetEligibility}
            raasEligibilityStatus={raasEligibilityStatus}
            submitOrderFailure={submitOrderFailure}
            isOrderLimitReached={isOrderLimitReached}
          />
        )}
        {showOrderSummary && (
          <CheckoutConfirmation
            {...orderSummary}
            product={product}
            offers={offers}
            trackBrowseMarketplace={this.trackBrowseMarketplace}
          />
        )}
        <p className="order-process__disclaimer">
          Limitations &amp; eligibility requirements apply.
        </p>
        <CustomReactMarkdown className="order-process__disclaimer" source={disclaimer} />
      </div>
    );
  }
}

OrderProcess.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  flowSteps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    active: PropTypes.bool,
    visible: PropTypes.bool,
  })).isRequired,
  offers: PropTypes.shape({
    description: PropTypes.string,
    modal: PropTypes.shape({
      header: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      subheader: PropTypes.string,
    }),
  }),
  termsForm: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  termsItemized: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  instructions: PropTypes.string,
  addressForm: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  accountForm: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  eligibilityQuestionsForm: PropTypes.arrayOf(PropTypes.shape({})),
  surveyQuestionsForm: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rebateCommitments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  inFlight: PropTypes.bool.isRequired,
  raasEligibilityStatus: PropTypes.string.isRequired,
  raasProgramMessage: PropTypes.string.isRequired,
  isOrderLimitReached: PropTypes.bool.isRequired,
  resetEligibility: PropTypes.bool.isRequired,
  shippingForm: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orderSummary: PropTypes.shape({}).isRequired,
  rebateAddress: PropTypes.shape({}).isRequired,
  submitOrderFailure: PropTypes.bool.isRequired,
  emailValidity: PropTypes.shape({
    isValid: PropTypes.bool,
  }).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string,
    online: PropTypes.bool,
    addOns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  programId: PropTypes.string.isRequired,
  disclaimer: PropTypes.string.isRequired,
};

OrderProcess.defaultProps = {
  offers: {},
  eligibilityQuestionsForm: [],
  instructions: null,
};

export default connect(mapStateToProps)(OrderProcess);
