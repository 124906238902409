import React from 'react';
import PropTypes from 'prop-types';
import Form from '@uplight/marketplace-forms';
import FormContainer from '../../../shared/components/FormContainer/FormContainer';
import getValueFromWindow from '../../../shared/helpers/getValueFromWindow';
import { controlsRendererMapSurvey } from '../Eligibility/formConfig';


const Survey = function Survey(props) {
  const tenantDisplayName = getValueFromWindow('globals', 'tenant').displayName;
  const {
    surveyQuestionsForm,
    reportFormValues,
    validateControls,
    onQueueComplete,
    inFlight,
    showInvalidFormMessage,
    onSubmitHandler,
  } = props;

  return (
    <div className="survey__container">
      <div className="order-process-step__header">A couple last things...</div>
      <div className="order-process-step__description">
        <p>Please answer a couple quick questions about your household.</p>
        <p>
          {`This step helps us keep ${tenantDisplayName} records up to date, identify future opportunities for you to save, and ensure you’re eligible for the free product.`}
        </p>
      </div>
      <div className="survey__header">Next steps</div>
      <FormContainer title="Required Questions">
        <Form
          formData={surveyQuestionsForm}
          reportFormValues={form => reportFormValues(form, 'survey')}
          validateControls={validateControls}
          controlsRendererMap={controlsRendererMapSurvey}
          onQueueComplete={onQueueComplete}
        />
      </FormContainer>
      {showInvalidFormMessage && (
        <div className="text-input-control__error--single-use">Please complete all of the required fields above</div>
      )}
      <button type="button" onClick={onSubmitHandler} className="survey-form__submit-btn">
        Finish &amp; go to shipping
        {inFlight && <span className="button-loader" />}
      </button>
    </div>
  );
};

Survey.propTypes = {
  surveyQuestionsForm: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  inFlight: PropTypes.bool.isRequired,
  reportFormValues: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  validateControls: PropTypes.string.isRequired,
  onQueueComplete: PropTypes.func.isRequired,
  showInvalidFormMessage: PropTypes.bool.isRequired,
};

export default Survey;
