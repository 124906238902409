/* eslint-disable complexity */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FocusRing } from '@react-aria/focus';
import classNames from 'classnames';
import priceFormatter from '../shared/helpers/priceFormatter';
import IconShipment from '../shared/icons/icon-shipment.svg';
import IconEligibility from '../shared/icons/icon-eligibility-lookup.svg';
import IconOffer from '../shared/icons/icon-offer.svg';
import IconCaret from '../shared/icons/icon-arrow-down.svg';
import isEnter from '../shared/helpers/isEnter';
import getValueFromWindow from '../shared/helpers/getValueFromWindow';


const iconMap = {
  offer: IconOffer,
  eligibility: IconEligibility,
  shipment: IconShipment,
};

class Program extends Component {
  static isUserBlockingOnError() {
    return false;
  }

  static getDomId() {
    return 'program';
  }

  constructor(props) {
    super(props);

    const breakpoint = 640;
    const isMobileView = window.innerWidth < breakpoint;

    this.toggleFlow = this.toggleFlow.bind(this);

    this.state = {
      isMobileView,
      isFlowOpen: !isMobileView,
      slideFlow: false,
    };

    this.baseUrl = getValueFromWindow('globals', 'baseUrl');
  }

  toggleFlow() {
    const { isFlowOpen } = this.state;

    this.setState({ isFlowOpen: !isFlowOpen, slideFlow: true });
  }

  render() {
    const {
      products, banner, steps, hiddenProducts,
    } = this.props;
    const {
      isFlowOpen,
      isMobileView,
      slideFlow,
    } = this.state;
    const flowStepsClassNames = classNames('program__flow-steps', {
      'program__flow-steps--hidden': (isMobileView && !slideFlow) || (slideFlow && !isFlowOpen),
    });
    const triggerIconClassNames = classNames('program__flow-trigger-icon', {
      'program__flow-trigger-icon--reverse': isFlowOpen,
    });

    return (
      <>
        {banner && (
          <div className="program__banner">
            <div className="program__banner-overlay" />
            <div className="program__banner-title">{banner.title}</div>
            <div className="program__banner-body">{banner.body}</div>
          </div>
        )}
        {steps.length > 0 && (
          <div className="program__flow">
            <FocusRing focusRingClass="program__flow-trigger--focused">
              <div
                className="program__flow-trigger"
                tabIndex={0}
                role="button"
                onClick={this.toggleFlow}
                onKeyPress={event => isEnter(event) && this.toggleFlow()}
                aria-hidden={!isMobileView}
                aria-expanded={isFlowOpen}
              >
                <div className="program__flow-trigger-icon-container">
                  <IconCaret className={triggerIconClassNames} />
                </div>
                Learn how it works
              </div>
            </FocusRing>
            <div className={flowStepsClassNames} aria-live="polite" aria-hidden={!isFlowOpen}>
              {steps.map(({ icon, title, body }, index) => {
                const Icon = iconMap[icon];
                const stepEnum = `${index + 1}. `;

                return (
                  <div className="program__flow-step" key={icon}>
                    <div className="program__flow-step-icon-container">
                      <Icon className="program__flow-step-icon" />
                    </div>
                    <div className="program__flow-step-title">
                      {stepEnum}
                      {title}
                    </div>
                    <div className="program__flow-step-body">{body}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <h1 className="program__title">Featured free products</h1>
        <div className="program__products">
          {products.map(({
            productId, name, subtitle, image, price, detailsUrl,
          }) => (hiddenProducts.includes(productId) ?
            null :
            (
              <div
                className="program__product-card"
                key={productId}
                role="button"
                tabIndex={0}
                onKeyPress={() => { window.location.assign(detailsUrl); }}
                onClick={() => { window.location.assign(detailsUrl); }}
              >
                <img className="program__product-image" src={image.url} alt={image.alt} />
                <div className="program__product-details-wrapper">
                  <div className="program__product-name">{name}</div>
                  <div className="program__product-subtitle">{subtitle}</div>
                  <div className="program__product-price">
                    {priceFormatter(`$${price}`)}
                    {' retail value'}
                  </div>
                  <div className="program__product-free">Free</div>
                  <a href={detailsUrl} className="program__product-link">
                    View details
                  </a>
                </div>
              </div>
            )))}
        </div>
      </>
    );
  }
}

Program.propTypes = {
  hiddenProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    productId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }).isRequired,
  })).isRequired,
  banner: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }),
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  })),
};

Program.defaultProps = {
  banner: null,
  steps: [],
};

export default Program;
