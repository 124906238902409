import React from 'react';
import PropTypes from 'prop-types';

import LocationIcon from '../../../shared/icons/icon-location.svg';


const ReadOnlyShippingAddress = function ReadOnlyShippingAddress({
  shippingAddress,
}) {
  const address2 = shippingAddress.address2 ? ` ${shippingAddress.address2}` : '';

  return (
    <div className="shipping-info__address-box">
      <LocationIcon className="shipping-info__address-box__icon" />
      <span className="shipping-info__address-box__copy">
        {`${shippingAddress.firstName} ${shippingAddress.lastName}`}
      </span>
      <span className="shipping-info__address-box__copy">
        {`${shippingAddress.address1}${address2}`}
      </span>
      <span className="shipping-info__address-box__copy">
        {`${shippingAddress.city}, ${shippingAddress.stateCode} ${shippingAddress.zipCode}`}
      </span>
    </div>
  );
};


ReadOnlyShippingAddress.propTypes = {
  shippingAddress: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    stateCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReadOnlyShippingAddress;
