import React from 'react';
import PropTypes from 'prop-types';


const ValidationInfo = function ValidationInfo({ instructions, tenant, validationMethod }) {
  const eligibilityInfo = (validationMethod === 'account' ?
    `Enter your ${tenant} account number` :
    'Enter your service address'
  );

  return (
    <>
      <div className="eligibility__header">Verify your account</div>
      <div className="eligibility__info">
        {eligibilityInfo}
      </div>
      { instructions && (
        <div className="eligibility__instructions">
          {instructions}
        </div>
      )}
    </>
  );
};

ValidationInfo.propTypes = {
  tenant: PropTypes.string,
  instructions: PropTypes.string,
  validationMethod: PropTypes.string.isRequired,
};

ValidationInfo.defaultProps = {
  tenant: '',
  instructions: '',
};

export default ValidationInfo;
