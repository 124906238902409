import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconShipment from '../shared/icons/icon-shipment.svg';
import IconEligibility from '../shared/icons/icon-eligibility.svg';
import IconReceipt from '../shared/icons/icon-receipt.svg';
import IconOffer from '../shared/icons/icon-offer.svg';
import IconEnrollment from '../shared/icons/icon-enrollment.svg';
import IconQuestion from '../shared/icons/icon-question.svg';


const stepIconMap = {
  offers: IconOffer,
  eligibility: IconEligibility,
  enrollment: IconEnrollment,
  shipping: IconShipment,
  receipt: IconReceipt,
  survey: IconQuestion,
};

const FlowHeader = function FLowHeaderComponent({ steps }) {
  let counter = 0;

  return (
    <div className="flow-header">
      <div className="flow-header-steps-wrapper">
        {
          steps.map(({
            id, label, active, visible,
          }) => {
            if (!visible) {
              return '';
            }

            counter += 1;

            const stepClass = classNames({
              'flow-header__step': true,
              'flow-header__step--active': active,
            });
            const Icon = stepIconMap[id];

            return (
              <div key={id} className={stepClass}>
                <div className="flow-header__step-info">
                  <Icon className="flow-header__step-info__icon" />
                  <span className="flow-header__step-name">{`${counter}. ${label}`}</span>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

FlowHeader.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    active: PropTypes.bool,
    visible: PropTypes.bool,
  })).isRequired,
};

export default FlowHeader;
