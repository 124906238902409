import genericApiErrorHandler from './genericApiErrorHandler';


const fetchData = function fetchDataFromApi(
  url,
  options,
  { successHandler, errorHandler, dispatch },
) {
  const errorHandlerFunction = errorHandler || genericApiErrorHandler;
  const errorStatus = 400;
  let responseStatus;

  return fetch(url, options)
    .then((response) => {
      responseStatus = response.status;
      return response.json();
    })
    .then((body) => {
      if (responseStatus >= errorStatus) {
        throw Error(body.errorMessage || 'System error');
      }

      return dispatch(successHandler(body, dispatch));
    })
    .catch((error) => {
      errorHandlerFunction(error, dispatch);
    });
};

export default fetchData;
