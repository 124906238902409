export default [
  {
    control: {
      type: 'radio',
    },
    fieldKey: 'shipping_info.shippingMethodID',
    id: 'shipping_info.shippingMethodID',
    formType: 'formGroup',
    label: 'Shipping Method',
    options: [
      {
        id: '001',
        value: '001',
        methodName: 'Standard (2-7 days)',
        methodPrice: 'Free',
      },
    ],
    order: 0,
    validation: {
      message: 'Choose shipping method',
      pattern: '.+',
    },
    value: '001',
  },
];
