import React from 'react';
import findControl from '@uplight/marketplace-forms/Forms/helpers/findControl';
import { SubmissionError } from '@uplight/marketplace-components';
import PropTypes from 'prop-types';
import { controlsRendererMap } from './formConfig';
import InfoIcon from '../../../shared/icons/icon-info.svg';
import getSubmissionErrorMessage from './helpers/getSubmissionErrorMessage';
import getValueFromWindow from '../../../shared/helpers/getValueFromWindow';
import ValidationInfo from './ValidationInfo';
import { RaasEligibilityEnum } from '../../../../config/constants';


/* eslint-disable-next-line max-params, complexity */
const AddressForm = function AddressForm(
  formData,
  controls,
  reportControlValues,
  classPrefixOverride,
  validateControls,
  passThroughProps,
) {
  const {
    toggleAddressInfo,
    showAddressInfo,
    raasEligibilityStatus,
    resetEligibility,
    validationMethod,
    instructions,
  } = passThroughProps;

  const firstName = findControl.byFieldKey(controls, 'identity_validation.primary.first_name');
  const lastName = findControl.byFieldKey(controls, 'identity_validation.primary.last_name');
  const address1 = findControl.byFieldKey(controls, 'identity_validation.primary.address_line_1');
  const address2 = findControl.byFieldKey(controls, 'identity_validation.primary.address_line_2');
  const city = findControl.byFieldKey(controls, 'identity_validation.primary.address_city');
  const state = findControl.byFieldKey(controls, 'identity_validation.primary.address_state');
  const zip = findControl.byFieldKey(controls, 'identity_validation.primary.address_zip_code');
  const phone = findControl.byFieldKey(controls, 'identity_validation.primary.email_address');
  const email = findControl.byFieldKey(controls, 'identity_validation.primary.phone_number');

  const addressPresent = firstName && lastName && address1 && address2 && city && state && zip;

  const tenant = getValueFromWindow('globals', 'tenant');

  /* eslint-disable max-len */
  return (
    <>
      <ValidationInfo
        tenant={tenant.displayName}
        instructions={instructions}
        validationMethod="address"
      />
      <div
        role="button"
        tabIndex={0}
        className="rebate-identity__address-usage"
        onKeyPress={() => toggleAddressInfo()}
        onClick={() => toggleAddressInfo()}
      >
        How will my address be used?
        <InfoIcon className="terms-and-conditions__info-icon" />
      </div>
      { showAddressInfo && (
        <div className="rebate-identity__reveal">
          To prevent fraudulent rebate claims, your service address will be used both to (1) check your eligibility for rebates and (2) as your shipping address.
          <div className="rebate-identity__reveal-important">{'You won\'t be able to modify your shipping address during check out.'}</div>
        </div>
      )}
      {addressPresent && (
        <fieldset className="identity-form__fieldset">
          <div className="identity-form__row">
            <div className="identity-form__field--half">
              <firstName.Control
                fieldData={firstName.fieldData}
                fieldId={firstName.fieldId}
                reportControlValues={reportControlValues}
                validateControls={validateControls}
                customControlRenderer={controlsRendererMap[firstName.fieldData.control.type]}
              />
            </div>
            <div className="identity-form__field--half">
              <lastName.Control
                fieldData={lastName.fieldData}
                fieldId={lastName.fieldId}
                reportControlValues={reportControlValues}
                validateControls={validateControls}
                customControlRenderer={controlsRendererMap[lastName.fieldData.control.type]}
              />
            </div>
          </div>
          { (email || phone) && (
            <div className="identity-form__row">
              { email && (
                <div className="identity-form__field--half">
                  <email.Control
                    fieldData={email.fieldData}
                    fieldId={email.fieldId}
                    reportControlValues={reportControlValues}
                    customControlRenderer={controlsRendererMap[email.fieldData.control.type]}
                    validateControls={validateControls}
                  />
                </div>
              )}
              { phone && (
                <div className="identity-form__field--half">
                  <phone.Control
                    fieldData={phone.fieldData}
                    fieldId={phone.fieldId}
                    reportControlValues={reportControlValues}
                    customControlRenderer={controlsRendererMap[phone.fieldData.control.type]}
                    validateControls={validateControls}
                  />
                </div>
              )}
            </div>
          )
          }
          <div className="identity-form__row">
            <div className="identity-form__field-street">
              <address1.Control
                fieldData={address1.fieldData}
                fieldId={address1.fieldId}
                reportControlValues={reportControlValues}
                validateControls={validateControls}
                customControlRenderer={controlsRendererMap[address1.fieldData.control.type]}
              />
            </div>
            <div className="identity-form__field-apt">
              <address2.Control
                fieldData={address2.fieldData}
                fieldId={address2.fieldId}
                reportControlValues={reportControlValues}
                validateControls={validateControls}
                customControlRenderer={controlsRendererMap[address2.fieldData.control.type]}
              />
            </div>
          </div>
          <div className="identity-form__row">
            <div className="identity-form__field--half">
              <city.Control
                fieldData={city.fieldData}
                fieldId={city.fieldId}
                reportControlValues={reportControlValues}
                customControlRenderer={controlsRendererMap[city.fieldData.control.type]}
                validateControls={validateControls}
              />
            </div>
            <div className="identity-form__field--half">
              <state.Control
                fieldData={state.fieldData}
                fieldId={state.fieldId}
                reportControlValues={reportControlValues}
                customControlRenderer={controlsRendererMap[state.fieldData.control.type]}
                validateControls={validateControls}
              />
            </div>
          </div>
          <div className="identity-form__row">
            <div className="identity-form__field--half">
              <zip.Control
                fieldData={zip.fieldData}
                fieldId={zip.fieldId}
                reportControlValues={reportControlValues}
                customControlRenderer={controlsRendererMap[zip.fieldData.control.type]}
                validateControls={validateControls}
              />
            </div>
          </div>
          <div className="identity-form__shipping-disclaimer">
            {`Free products from ${tenant.displayName} are only available for eligible customers who live in ${tenant.state.name}.`}
          </div>
          { !resetEligibility && raasEligibilityStatus === RaasEligibilityEnum.ACCOUNT_NOT_FOUND && (
            <div className="identity-errors">
              <SubmissionError message={getSubmissionErrorMessage(validationMethod)} />
            </div>
          )}
        </fieldset>
      )}
    </>
  );
};

AddressForm.propTypes = {
  controls: PropTypes.shape({}).isRequired,
  passThroughProps: PropTypes.shape({
    tenant: PropTypes.string.isRequired,
    toggleAddressInfo: PropTypes.func.isRequired,
    showAddressInfo: PropTypes.bool.isRequired,
    showSubmissionErrorMessage: PropTypes.bool.isRequired,
    submissionErrorMessage: PropTypes.string.isRequired,
    submissionResult: PropTypes.string.isRequired,
  }).isRequired,
};

export default AddressForm;
