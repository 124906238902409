import { createSelector } from 'reselect';
import { RaasEligibilityEnum } from '../../../config/constants';
import shippingMethodFields from '../fixtures/shippingForms';
import filterValidFields from '../components/Shipping/helpers/filterValidFields';
import formWithKeys from '../../shared/helpers/formWithKeys';
import getAddressFormInstructions from '../../shared/helpers/getAddressFormInstructions';
import Analytics from '../../analytics/GoogleAnalytics';
import {
  FailedEligibilityAccountNotFound,
  FailedEligibilityNotEligible,
} from '../analytics/events';


const getResponse = state => state.orderProcess.response;
const getEmailResponse = state => state.orderProcess.emailResponse;

export const getCurrentStep = state => (state.orderProcess.response &&
  state.orderProcess.response.currentStep) || '';

export const getFlowSteps = state => (state.orderProcess.response &&
  state.orderProcess.response.flowSteps) || [];

export const getRaasEligibilityStatus = createSelector(
  [getResponse],
  (response) => {
    if (!response || !response.raasEligibility) {
      return '';
    }

    const googleAnalytics = new Analytics();

    switch (response.raasEligibility) { // eslint-disable-line
      case RaasEligibilityEnum.NOT_ELIGIBLE:
        googleAnalytics.trackEvent(FailedEligibilityNotEligible);
        break;
      case RaasEligibilityEnum.ACCOUNT_NOT_FOUND:
        googleAnalytics.trackEvent(FailedEligibilityAccountNotFound);
        break;
    }

    return response.raasEligibility;
  },
);

export const getRaasProgramMessage = createSelector(
  [getResponse],
  (response) => {
    if (!response || !response.raasProgramMessage) {
      return '';
    }

    return response.raasProgramMessage;
  },
);

export const getIsOrderLimitReached = createSelector(
  [getResponse],
  (response) => {
    if (!response || !response.isOrderLimitReached) {
      return false;
    }
    return !!response.isOrderLimitReached;
  },
);

export const getSubmitOrderFailure = createSelector(
  [getResponse],
  (response) => {
    if (response && response.status === 'failure') {
      return true;
    }
    return false;
  },
);

export const getTermsAndConditionsForm = createSelector(
  [getResponse],
  (response) => {
    if (
      !response ||
      response.currentStep !== 'eligibility'
    ) {
      return [];
    }

    return formWithKeys('termsAndConditions', response.forms);
  },
);

export const getTermsAndConditionsItemized = createSelector(
  [getResponse],
  (response) => {
    if (
      !response ||
      response.currentStep !== 'eligibility'
    ) {
      return [];
    }

    return response.forms.terms_and_conditions.itemized;
  },
);

export const getEligibilityQuestionsForm = createSelector(
  [getResponse],
  (response) => {
    if (
      !response ||
      response.currentStep !== 'eligibility'
    ) {
      return [];
    }

    return formWithKeys('eligibilityQuestions', response.forms);
  },
);

export const getSurveyQuestionsForm = createSelector(
  [getResponse],
  (response) => {
    if (
      !response ||
      !['enrollment', 'survey'].includes(response.currentStep)
    ) {
      return [];
    }

    return formWithKeys('surveyQuestions', response.forms);
  },
);

export const getRebateCommitments = createSelector(
  [getResponse],
  (response) => {
    if (
      !response ||
      response.currentStep !== 'enrollment'
    ) {
      return [];
    }

    return formWithKeys('rebateCommitments', response.forms, true);
  },
);

export const getShippingForm = createSelector(
  [getResponse],
  (response) => {
    if (
      !response ||
      !response.forms ||
      response.currentStep !== 'shipping'
    ) {
      return [];
    }
    const filtered = filterValidFields(response.forms.form, response.rebateAddress);

    return [...filtered, ...shippingMethodFields];
  },
);

export const getRebateAddress = createSelector(
  [getResponse],
  (response) => {
    if (
      !response ||
      !response.rebateAddress ||
      response.currentStep !== 'shipping'
    ) {
      return {};
    }
    return response.rebateAddress;
  },
);

export const getInstructions = createSelector(
  [getResponse],
  (response) => {
    if (
      !response ||
      !response.forms ||
      response.currentStep !== 'eligibility'
    ) {
      return '';
    }

    return getAddressFormInstructions(response.forms);
  },
);

export const getAddressForm = createSelector(
  [getResponse],
  (response) => {
    if (
      !response ||
      !response.forms ||
      response.currentStep !== 'eligibility'
    ) {
      return [];
    }

    return formWithKeys('address', response.forms);
  },
);

export const getAccountForm = createSelector(
  [getResponse],
  (response) => {
    if (
      !response ||
      !response.forms ||
      response.currentStep !== 'eligibility'
    ) {
      return [];
    }

    return formWithKeys('account', response.forms);
  },
);

export const getOrderSummary = createSelector(
  [getResponse],
  (response) => {
    if (
      !response ||
      response.currentStep !== 'receipt'
    ) {
      return {};
    }

    const { order } = response;

    return {
      orderNumber: order.orderId,
      selectedShippingMethod: { displayName: 'Standard shipping' },
      shippingAddress: transformAddress(order.shippingAddress),
      contactInfo: {
        email: order.customerInfo.email,
        phoneNumber: order.shippingAddress && order.shippingAddress.phone,
      },
    };
  },
);

export const getEmailValidity = createSelector(
  [getEmailResponse],
  (response) => {
    if (!response) {
      return {};
    }
    return {
      isValid: response.valid_format && response.valid_dns_records,
    };
  },
);

const transformAddress = function transformAddress(address) {
  if (address) {
    return {
      address1: address.address1,
      address2: address.address2 || '',
      city: address.city,
      firstName: address.first_name,
      lastName: address.last_name,
      zipCode: address.postal_code,
      stateCode: address.state_code,
    };
  }

  return null;
};
