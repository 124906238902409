import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import CaretIcon from '../../shared/icons/icon-caret.svg';
import InfoIcon from '../../shared/icons/icon-info.svg';
import NavPane from './components/NavPane';
import isEnter from '../../shared/helpers/isEnter';


const NavDrawer = ({
  isOpen,
  items,
  menuTop,
  toggleOpen,
}) => {
  if (items.length === 0) {
    return null;
  }

  const [activePane, setActivePane] = useState(null);
  const [showRoot, setShowRoot] = useState(true);
  const iconMap = {
    info: <InfoIcon className="nav-drawer__link-icon" />,
  };

  const goToNavPane = (groupId) => {
    setActivePane(groupId);
    setShowRoot(false);
  };

  const goToRoot = () => {
    setActivePane(null);
    setShowRoot(true);
  };

  const closeNavMenu = ({ key }) => {
    const escapeKeyPressed = key === 'Escape';

    if (isOpen && escapeKeyPressed) {
      toggleOpen();
    }
  };

  const drawerClass = classNames('nav-drawer', {
    'nav-drawer--open': isOpen,
  });
  const positionUnderHeader = { height: `calc(100% - ${menuTop}px)` };
  const tabIndex = (isOpen ? 0 : -1);

  return (
    <div
      aria-hidden={!isOpen}
      aria-label="Mobile menu"
      className={drawerClass}
      role="dialog"
      style={positionUnderHeader}
    >
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <ul
        className="nav-drawer__link-list"
        onKeyUp={closeNavMenu}
      >
        {/* eslint-disable-next-line complexity */}
        {items.map((navItem) => {
          const subGroups = navItem.items || [];
          const id = `${navItem.name}_id`;

          if (showRoot && subGroups.length === 0) {
            return (
              <li className="nav-drawer__link-list__item" key={uuid()}>
                <a
                  href={navItem.url}
                  className="nav-drawer__link nav-drawer__link--active"
                  tabIndex={tabIndex}
                >
                  {navItem.name}
                </a>
              </li>
            );
          }

          return (
            <React.Fragment key={uuid()}>
              {showRoot && (
                <li
                  className="nav-drawer__link-list__item"
                >
                  <div
                    className="nav-drawer__link"
                    onClick={() => goToNavPane(`${id}_subGroups`)}
                    onKeyUp={
                      event => isEnter(event) && goToNavPane(`${id}_subGroups`)
                    }
                    role="button"
                    tabIndex={tabIndex}
                  >
                    <span className="nav-drawer__link-text">
                      {navItem.icon && iconMap[navItem.icon]}
                      {navItem.name}
                    </span>
                    <CaretIcon className="nav-drawer__caret" />
                  </div>
                </li>
              )}
              <NavPane
                activePane={activePane}
                backItemId={id}
                displayName={navItem.name}
                backHandler={goToRoot}
                items={navItem.items}
                paneId={`${id}_subGroups`}
              />
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};

NavDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ])).isRequired,
  menuTop: PropTypes.number.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default NavDrawer;
