import React from 'react';


const Loader = function Loader() {
  return (
    <div className="giveaway-hub-loader-container">
      <div className="giveaway-hub-loader">
        <div className="giveaway-hub-loader--dot" />
        <div className="giveaway-hub-loader--dot" />
        <div className="giveaway-hub-loader--dot" />
        <div className="giveaway-hub-loader--dot" />
        <div className="giveaway-hub-loader--dot" />
        <div className="giveaway-hub-loader--dot" />
        <div className="giveaway-hub-loader--text" />
      </div>
    </div>
  );
};

export default Loader;
