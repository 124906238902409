const keyMap = {
  address: ['identity_validation', 'primary'],
  account: ['identity_validation', 'secondary'],
  termsAndConditions: ['terms_and_conditions'],
  shipping: ['forms'],
  eligibilityQuestions: ['eligibility_questions'],
  surveyQuestions: ['survey_questions'],
  rebateCommitments: ['rebate_commitments'],
};

const transformSelectField = (field) => {
  const { options, validation } = field;
  let { value } = field;
  let placeholder = null;

  if (!field.control || field.control.type !== 'select' || !value || !validation) {
    return field;
  }

  const validator = new RegExp(validation.pattern);

  if (!validator.test(value)) {
    value = '';
  }

  const transformedOptions = options.reduce((validOptions, option) => {
    if (!validator.test(option.value)) {
      placeholder = option.label;

      return validOptions;
    }

    validOptions.push(option);

    return validOptions;
  }, []);

  return {
    ...field,
    placeholder,
    value,
    options: transformedOptions,
  };
};

const getFormFields = (container) => {
  const { form } = container;

  if (!form) {
    return [];
  }

  return form.map(field => ({
    ...transformSelectField(field),
    id: field.fieldKey,
  }));
};

const formWithKeys = function formWithKeysHelper(key, formData = {}, allKeys = false) {
  const formKeys = keyMap[key];
  let tempForm = formData;

  if (formKeys && formKeys.length > 0) {
    for (let i = 0; i < formKeys.length; i += 1) {
      if (!tempForm[formKeys[i]]) {
        return [];
      }
      tempForm = tempForm[formKeys[i]];
    }
  }

  if (allKeys) {
    return Object.keys(tempForm).map(eligibilityId => ({
      eligibilityId,
      form: getFormFields(tempForm[eligibilityId]),
      programId: tempForm[eligibilityId].program,
    }));
  }

  return getFormFields(tempForm);
};

export default formWithKeys;
