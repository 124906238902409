const productModel = function googleAnalyticsProductModel(product) {
  return {
    id: product.id,
    brand: product.brand,
    name: product.name,
    price: '0',
    quantity: 1,
    category: 'Free Product',
  };
};

export default productModel;
