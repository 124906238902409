import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { CustomReactMarkdown } from '@uplight/marketplace-components';
import Tabs from './components/Tabs';


class Product extends Component {
  static isUserBlockingOnError() {
    return false;
  }

  static getDomId() {
    return 'product_details';
  }

  constructor(props) {
    super(props);

    this.renderDetails = this.renderDetails.bind(this);
    this.renderInstallation = this.renderInstallation.bind(this);
  }

  renderDetails() {
    const {
      shortDescription,
    } = this.props;

    return (
      <>
        <h2 className="product-description__title">Description</h2>
        <CustomReactMarkdown className="product-description__short-description" source={shortDescription} />
      </>
    );
  }

  renderInstallation() {
    const {
      installation: { description, video, disclaimer },
    } = this.props;

    return (
      <>
        <h2 className="product-description__title">Installation</h2>
        {description && <CustomReactMarkdown className="product-description__short-description" source={description} />}
        {video && (
          <>
            <h3 className="product-description__media-title">Watch this step-by-step installation video</h3>
            <div className="product-description__media-player-wrapper">
              <ReactPlayer
                width="100%"
                height="100%"
                controls
                className="product-description__media-player"
                url={`https://www.youtube.com/watch?v=${video}`}
              />
            </div>
          </>
        )}
        {disclaimer && <CustomReactMarkdown className="product-description__disclaimer" source={disclaimer} />}
      </>
    );
  }

  render() {
    const {
      installation,
    } = this.props;

    return (
      <div className="se-tier se-tier--bg-light-grey">
        <div className="se-row">
          {!installation && this.renderDetails()}
          {installation && (
          <Tabs
            tabs={[
              {
                id: 'details',
                handle: 'Product Info',
                content: this.renderDetails(),
              },
              {
                id: 'install',
                handle: 'Installation',
                content: this.renderInstallation(),
              },
            ]}
          />
          )}
        </div>
      </div>
    );
  }
}

Product.propTypes = {
  shortDescription: PropTypes.string.isRequired,
  installation: PropTypes.shape({
    description: PropTypes.string,
    video: PropTypes.string,
    disclaimer: PropTypes.string,
  }),
};

Product.defaultProps = {
  installation: null,
};

export default Product;
