const scrollConditions = {
  up: {
    scrollStepPx: 20,
    stopCondition: (currentOffset, targetOffset) => currentOffset <= targetOffset,
  },
  down: {
    scrollStepPx: -20,
    stopCondition: (currentOffset, targetOffset) => targetOffset - currentOffset <= 0,
  },
};

const animateScroll = function animateScrollHelper(targetOffset) {
  let currentOffset = window.pageYOffset;
  let int = null;
  const scrollSpeed = 5;
  let scrollDirection;

  if (currentOffset > targetOffset) {
    scrollDirection = 'up';
  }

  if (currentOffset < targetOffset) {
    scrollDirection = 'down';
  }

  if (currentOffset === targetOffset) {
    return;
  }

  const { scrollStepPx, stopCondition } = scrollConditions[scrollDirection];

  int = setInterval(() => {
    currentOffset -= scrollStepPx;

    window.scrollTo(0, currentOffset);
    if (stopCondition(currentOffset, targetOffset)) {
      clearInterval(int);
    }
  }, scrollSpeed);
};

export default animateScroll;
